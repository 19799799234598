var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-item-group',{key:_vm.reload,attrs:{"value":_vm.selectedMenuItem,"dark":""}},[_vm._l((_vm.menuItems),function(item){return _c('v-item',{key:item.title,scopedSlots:_vm._u([{key:"default",fn:function({ active, on: vitem, attrs: itemAttrs }){return [(item.submenu.length)?_c('v-menu',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => {}),expression:"() => {}"}],attrs:{"bottom":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs: menuAttrs }){return [_c('v-badge',{style:({
            'z-index':
              item.title == 'Documents' &&
              _vm.$store.getters.requiringMyAttention > 0
                ? '100'
                : '0',
          }),attrs:{"value":item.title == 'Documents' &&
            _vm.$store.getters.requiringMyAttention > 0,"color":"red","content":"!","overlap":""}},[(_vm.calculateShow(item))?_c('v-btn',_vm._g(_vm._b({class:`${active ? 'msaBlue--text' : ''} text-capitalize`,style:(active ? _vm.activeBtnStyles : {}),attrs:{"outlined":active,"text":!active,"height":"45","min-width":"100","tile":""},on:{"click":function($event){item.title == 'Administration'
                ? null
                : _vm.goToRoute(item.submenu[0])}}},'v-btn',{ ...menuAttrs, ...itemAttrs },false),{ ...menu, ...vitem }),[_vm._v(" "+_vm._s(item.title)+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)]}}],null,true)},[_c('v-list',_vm._l((item.submenu),function(subitem){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
            subitem.title !== 'Mobile App' ||
            _vm.$store.getters.user.is1LifeMobileSettingEnabled
          ),expression:"\n            subitem.title !== 'Mobile App' ||\n            $store.getters.user.is1LifeMobileSettingEnabled\n          "}],key:subitem.title,attrs:{"ripple":"","disabled":subitem.title === 'Scheduler (Mobile)' &&
            !_vm.$store.getters.user.isSchedulerEnabled},on:{"click":function($event){return _vm.goToRoute(subitem)}}},[_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))])],1)}),1)],1):_c('v-btn',_vm._g(_vm._b({class:`
        ${active ? 'msaBlue--text' : ''} text-capitalize`,style:(active ? _vm.activeBtnStyles : {}),attrs:{"outlined":active,"text":!active,"height":"45","min-width":"100","tile":""},on:{"click":function($event){return _vm.goToRoute(item)}}},'v-btn',{ ...itemAttrs },false),{ ...vitem }),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)})}),(!_vm.$store.getters.isInternalAdmin)?_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function({ on: vitem, attrs: itemAttrs }){return [_c('v-menu',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => {}),expression:"() => {}"}],attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs: menuAttrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"text":"","tile":"","min-width":"100","height":"45"}},'v-btn',{ ...menuAttrs, ...itemAttrs },false),{ ...menu, ...vitem }),[_vm._v(" Support "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goToLink(_vm.$constants.SUPPORT_LINKS.KNOWLEDGE_BASE)}}},[_c('v-list-item-title',[_vm._v("Knowledge Base")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.goToLink(_vm.$constants.SUPPORT_LINKS.GET_LIVE_HELP)}}},[_c('v-list-item-title',[_vm._v("Get Live Help")])],1),(_vm.isWebAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.goToLink(_vm.$constants.SUPPORT_LINKS.GET_LIVE_TRAINING)}}},[_c('v-list-item-title',[_vm._v("Get Live Training")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.goToLink(_vm.$constants.SUPPORT_LINKS.CONTACT_US)}}},[_c('v-list-item-title',[_vm._v("Contact Us")])],1)],1)],1)]}}],null,false,1044613095)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }