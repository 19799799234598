import Vue from 'vue';
import App from '@/App.vue';
import axios from '@/plugins/axios';
import { router } from '@/plugins/router';
import store from '@/plugins/store';
import vuetify from '@/plugins/vuetify';
import helpers from './plugins/helpers';
import { DateTime } from 'luxon';
import * as constants from './constants.js';
import VueSignaturePad from 'vue-signature-pad';
import GmapVue from 'gmap-vue';
import CustomizedAutoComplete from '@/components/CustomizedAutoComplete.vue';

Vue.use(VueSignaturePad);
require('@/assets/styles/custom.css');

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
  },
  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
});

Vue.use(axios, {
  useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
  baseURL: process.env.VUE_APP_BASE_URL,
});
Vue.use(helpers);
Vue.prototype.$constants = constants;
Vue.config.productionTip = false;

Vue.filter(
  'filterAsLocalDateTime',
  function (value, mask = 'yyyy-LL-dd HH:mm') {
    return value
      ? DateTime.fromISO(new Date(value).toISOString()).toLocal().toFormat(mask)
      : '';
  },
);

Vue.filter('filterAsLocalTime', function (value, mask = 'HH:mm') {
  return value
    ? DateTime.fromISO(new Date(value).toISOString()).toLocal().toFormat(mask)
    : '';
});

Vue.filter('filterAsLocalDate', function (value, mask = 'yyyy-LL-dd') {
  return value
    ? DateTime.fromISO(new Date(value).toISOString()).toLocal().toFormat(mask)
    : '';
});

Vue.filter('filterDate', function (value, placeholder) {
  if (!value) {
    return placeholder;
  }

  value = value.replace(/-/g, '/');

  let date = new Date(value.toString().substring(0, 21));

  if (!(date instanceof Date) || isNaN(date)) {
    return placeholder;
  }
  let dateTime = date.toString().substring(0, 15);
  return dateTime;
});

// Register a global custom directive called `v-blur` that prevents focus on buttons after click
Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur();
  },
});

Vue.component('CustomizedAutoComplete', CustomizedAutoComplete);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
