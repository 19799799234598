<template>
  <v-dialog v-model="showDialog" width="unset" :persistent="isPersistent">
    <v-card>
      <v-card-title
        :class="
          isWarning
            ? 'accent-4 headline text-center amber'
            : 'accent-4 headline text-center success'
        "
      >
        <div class="text-center" style="width: 100%">
          <v-icon v-if="isWarning" class="mr-2" large color="white">
            mdi-alert
          </v-icon>
          {{ dialogTitle }}
        </div>
      </v-card-title>
      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <v-card-text class="text-center mt-3" v-html="dialogText" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isNoButtonHidden"
          color="msaBlue"
          text
          @click="
            showDialog = false;
            noHandler();
          "
        >
          {{ negativeButtonText }}
        </v-btn>
        <v-btn
          v-if="!isYesButtonHidden"
          class="msaBlue white--text"
          @click="
            showDialog = false;
            yesHandler();
          "
        >
          {{ positiveButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogWindow',
  data() {
    return {
      showDialog: false,
      showMessage: false,
      dialogText: '',
      dialogTitle: '',
      yesHandler: null,
      noHandler: null,
      positiveButtonText: 'Yes',
      negativeButtonText: 'No',
      isWarning: true,
      isActive: false,
      singleSelect: false,
      isPersistent: false,
    };
  },
  computed: {
    isYesButtonHidden() {
      return this.yesHandler == null;
    },
    isNoButtonHidden() {
      return this.noHandler == null;
    },
  },
  methods: {
    show(
      title,
      message,
      positiveHandler,
      negativeHandler,
      positiveButtonText = 'Yes',
      negativeButtonText = 'No',
      isPersistent = false,
      isWarning = true,
    ) {
      if (this.showDialog == false) {
        this.dialogTitle = title;
        this.dialogText = message;
        this.yesHandler = positiveHandler;
        this.positiveButtonText = positiveButtonText;
        this.negativeButtonText = negativeButtonText;
        this.noHandler = negativeHandler;
        this.isPersistent = isPersistent;
        this.isWarning = isWarning;
        this.showDialog = true;
      }
    },
  },
};
</script>
