<template>
  <v-dialog width="500" v-model="helpDialog">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn
        class="mx-3"
        color="msaBlue"
        outlined
        v-blur
        v-bind="attrs"
        v-on="{ ...dialog }"
        x-small
        icon
        style="border-radius: 5px; z-index: 999"
      >
        <v-icon color="white--text" x-small> mdi-help </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Help Center </v-card-title>
      <v-card-text class="pt-4 text-subtitle-1">
        <span v-if="shouldDisplayArticles">
          Page Relevant Articles
          <ul>
            <li
              class="text-subtitle-2"
              v-for="article in articles"
              :key="article.id"
            >
              <a :href="article.articleLink" target="_blank">
                {{ article.name }}
              </a>
            </li>
          </ul>
          <v-divider class="my-2"></v-divider>
        </span>
        Getting Started
        <ul class="text-subtitle-2">
          <li>
            <a :href="knowledgebaseLink" target="_blank"> Knowledge Base </a>
          </li>
        </ul>
        <v-divider class="my-2"></v-divider>
        Get Help
        <ul class="text-subtitle-2">
          <li>
            <a href="https://1lifewss.com/contact-support/" target="_blank">
              Contact Us
            </a>
          </li>
          <li>
            <a href="https://calendly.com/1lifesupport" target="_blank">
              Live Training
            </a>
          </li>
        </ul>
      </v-card-text>
      <v-card-text align="right" class="pb-n2">
        <v-btn class="white--text" color="msaBlue" @click="helpDialog = false">
          OK
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'HelpDialog',
  data() {
    return {
      helpDialog: false,
      helpArticles: [],
      helpMessage:
        'This section is for articles related to the page you are viewing. More articles are on the way! If you need support, please check our Other Support Offerings below or our Knowledge Base above.',
    };
  },
  watch: {
    helpDialog(val) {
      if (val) {
        this.load();
      }
    },
  },
  computed: {
    shouldDisplayArticles() {
      return this.articles.length > 0;
    },
    articles() {
      if (!this.$route.name) {
        return [];
      }

      return this.helpArticles.filter((item) => {
        if (item.routeName.toLowerCase() === this.$route.name.toLowerCase()) {
          return true;
        }

        return false;
      });
    },
    knowledgebaseLink() {
      // Used to check is accessing any path under the Administration tab
      const regex = new RegExp('^/admin');

      if (regex.test(this.$route.path)) {
        return 'https://knowledgebase.1lifesoftware.com/collection/1-web-administrator';
      }

      return 'https://knowledgebase.1lifesoftware.com/collection/6-user';
    },
  },
  methods: {
    load() {
      this.$axios.post('get-help-articles?format=json', {}).then((response) => {
        this.helpArticles = response.data.filter((article) => {
          if (
            article.permissions ||
            article.permissions == this.$constants.USER_TYPES.NORMAL_USER
          ) {
            var hasPermission =
              article.permissions == this.$store.getters.user.type;

            if (hasPermission) {
              return article;
            }
            return;
          }

          return article;
        });
      });
    },
  },
};
</script>
