<template>
  <v-container fluid>
    <v-row v-if="crumbItems.length">
      <v-col cols="12">
        <v-breadcrumbs
          :items="crumbItems"
          :style="{
            'border-left': '1px solid #3564ac',
            'border-bottom': '1px solid #3564ac',
            'border-right': '1px solid #3564ac',
            'border-bottom-left-radius': '5px',
            'border-bottom-right-radius': '5px',
            'background-color': 'white',
          }"
          class="pa-2"
          large
        >
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :disabled="item.disabled"
              :exact="true"
              :to="{ ...item.to }"
              style="overflow-wrap: anywhere"
            >
              {{ item.text | truncate }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row v-if="submenuItems.length" :key="reload">
      <v-col cols="12" :class="crumbItems.length ? '' : 'mt-6'">
        <v-item-group
          :value="selectedSubmenuItem"
          :style="{
            background: 'linear-gradient(to bottom,  #0a5fb1 0%,#093f79 100%)',
            borderRadius: '5px',
          }"
          dark
        >
          <span v-for="(item, index) in submenuItems" :key="item.title">
            <v-item v-slot="{ active, on, attrs }">
              <v-badge
                color="red"
                content="!"
                overlap
                :value="
                  item.title == 'Requiring My Attention' &&
                  $store.getters.requiringMyAttention > 0
                "
                :style="{
                  'z-index':
                    item.title == 'Requiring My Attention' &&
                    $store.getters.requiringMyAttention > 0
                      ? '100'
                      : '0',
                }"
              >
                <v-btn
                  :class="`${active ? 'msaBlue--text' : ''} text-capitalize`"
                  :text="!active"
                  :outlined="active"
                  :style="[
                    btnStyles(index),
                    active
                      ? {
                          background:
                            'linear-gradient(to bottom,  #f2f9fe 0%,#d6f0fd 100%)',
                        }
                      : {},
                  ]"
                  tile
                  height="45"
                  min-width="100"
                  @click="gotoSubRoute(item, index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.title }}
                </v-btn>
              </v-badge>
            </v-item>
          </span>
        </v-item-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MenuBreadcrumb',
  filters: {
    truncate(text, length = 50) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  data() {
    return {
      selectedSubmenuItem: -1,
      reload: 1,
    };
  },
  computed: {
    crumbItems() {
      let result = [];
      let crumbs;
      if (!this.$route.name || !this.$route.meta.crumbs) {
        return result;
      }

      // get all parent root views with crumbs
      const matched = this.$route.matched.filter(
        (view) => view.name.includes('Root') && view.meta && view.meta.crumbs,
      );

      // concat crumbs from the root views
      matched.forEach((view) => {
        crumbs = view.meta.crumbs;
        if (typeof crumbs == 'function') {
          result = result.concat(crumbs(this.$store));
        } else {
          result = result.concat(crumbs);
        }
      });

      // concat the rest crumbs from the current view
      crumbs = this.$route.meta.crumbs;
      if (typeof crumbs == 'function') {
        result = result.concat(crumbs(this.$store));
      } else {
        result = result.concat(crumbs);
      }

      return result;
    },
    submenuItems() {
      if (this.$route.meta.submenu) {
        if (typeof this.$route.meta.submenu == 'function') {
          return this.$route.meta.submenu(this.$store);
        } else {
          return this.$route.meta.submenu;
        }
      }

      // check if any root view has sub menu
      const matched = this.$route.matched.filter(
        (view) => view.name.includes('Root') && view.meta && view.meta.submenu,
      );

      if (matched.length) {
        // get the cloest root view's sub menu if there is more than 1
        // the reason to take the last item is that
        // $route.matched returns matched views from far to close
        // so after filter, the last one will be the cloest one
        const matchedParentRoot = matched[matched.length - 1];

        if (typeof matchedParentRoot.meta.submenu == 'function') {
          return matchedParentRoot.meta.submenu(this.$store);
        } else {
          return matchedParentRoot.meta.submenu;
        }
      }

      return [];
    },
  },
  methods: {
    gotoSubRoute(item) {
      if (item.routeName == '') {
        this.launchApp(item.action);
      } else {
        if (this.$route.name != item.routeName) {
          const route = { name: item.routeName };
          if (item.query && item.query.reset) {
            this.$store.commit('updatePersistentSettings', {
              key: item.routeName,
              value: item.query.value,
            });
          }
          this.$router.push(route);
        }
      }
    },
    setupMenu() {
      const index = this.submenuItems.findIndex(
        (view) => view.routeName == this.$route.name,
      );

      if (index != -1) {
        this.$nextTick(() => {
          this.selectedSubmenuItem = index;
        });
      }
    },
    async launchApp(action) {
      var windowReference = window.open();
      let respose = await this.$axios.post('get-transfer-token?format=json', {
        loaderText: 'Connecting....',
      });
      let url = action + '&token=' + respose.data;
      windowReference.location = url;
    },
    btnStyles(index) {
      if (index == 0) {
        return {
          'border-bottom-left-radius': '5px',
          'border-top-left-radius': '5px',
        };
      }

      if (index == this.submenuItems.length) {
        return {
          'border-bottom-right-radius': '5px',
          'border-top-right-radius': '5px',
        };
      }
      return {};
    },
  },
  mounted() {
    this.$nextTick(() => this.setupMenu());
  },
};
</script>

<style></style>
