import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
      },
      light: {
        primary: '#3564ac',
        msaBlueLight: '#4C7DC8',
        basicNew: '#4EB84D',
        secondary: '#42A5F5',
        accent: '#0091EA',
        msaBlue: '#3564ac',
        lightBlue: '#f5f7fa',
        freetrial: '#47CoDC',
        freemium: '#F6AD58',
        subscription: '#34CCAB',
        aLaCarte: '#207Ab4',
        basic: '#39ADAF',
        peaceOfMind: '#0A8996',
        freedomToOperate: '#215CA7',
        allAccess: '#632C94',
        testAccount: '#C62828',
        basicOld: '#4EB84D',
        menu: '#FCFDFF',
        menuFont: '#74757A',
        lightBg: '#ebf1fc',
        lightGrey: '#F0F0F0',
        elementType: '#B4C4DE',
        headerTextGrey: '#7C7C7C',
        msaGreen: '#dff0d8',
        msaOrange: '#EB9316',
        darkGrey: '#777777',
        cyan: '#5BC0DE',
        training: '#F8BBD0',
        trainingPlus: '#F06292',
      },
    },
  },
});
