export const MENU = [
  {
    title: 'Companies',
    routeName: 'CompanyList',
    submenu: [
      { title: 'Company List', routeName: 'CompanyList' },
      { title: 'Groups', routeName: 'IACompanyGroups' },
      { title: 'Users', routeName: 'Users' },
    ],
  },
  {
    title: 'News Feed',
    routeName: 'NewsFeeds',
    submenu: [],
  },
  {
    title: 'Resources',
    routeName: 'PublicDocuments',
    submenu: [],
  },
  {
    title: 'Training',
    routeName: 'IaTrainingCourses',
    submenu: [],
  },
  {
    title: 'Forms',
    routeName: 'PublicFormTemplates',
    submenu: [],
  },
  {
    title: 'Labels',
    routeName: 'IALabels',
    submenu: [],
  },
  {
    title: 'Blacklisted Emails',
    routeName: 'BlacklistedEmails',
    submenu: [],
  },
  {
    title: 'Requirement Templates',
    routeName: 'RequirementTemplates',
    submenu: [],
  },
  {
    title: 'Reports',
    routeName: 'WebLoginAudits',
    submenu: [
      { title: 'Web Login Audits', routeName: 'WebLoginAudits' },
      {
        title: 'Document Download Audits',
        routeName: 'DocumentDownloadAudits',
      },
    ],
  },
];

export const LABELS_SUBMENU = (store) =>
  store.getters.selectedLabel.id == -1
    ? [
        {
          title: 'Details',
          routeName: 'LabelDetail',
        },
      ]
    : [
        {
          title: 'Details',
          routeName: 'LabelDetail',
        },
        {
          title: 'Companies',
          routeName: 'LabelCompanies',
        },
        {
          title: 'Documents',
          routeName: 'LabelDocuments',
        },
        {
          title: 'Courses',
          routeName: 'LabelCourses',
        },
        {
          title: 'Form Templates',
          routeName: 'LabelFormTemplates',
        },
      ];

export const COMPANY_DETAILS_SUBMENU = (store) =>
  store.getters.selectedCompany.id == -1
    ? [
        {
          title: 'Details',
          routeName: 'CompanyDetail',
        },
      ]
    : [
        {
          title: 'Details',
          routeName: 'CompanyDetail',
        },
        {
          title: 'Users',
          routeName: 'IACompanyUsers',
        },
        {
          title: 'Sync Logs',
          routeName: 'IACompanySyncLogs',
        },
        {
          title: 'Labels',
          routeName: 'CompanyLabels',
        },
        {
          title: 'Notes',
          routeName: 'CompanyNotes',
        },
        {
          title: 'Form Templates',
          routeName: 'IACompanyFormTemplates',
        },
      ];

export const COMPANIES_SUBMENU = [
  {
    title: 'Company List',
    routeName: 'CompanyList',
  },
  {
    title: 'Groups',
    routeName: 'IACompanyGroups',
  },
  {
    title: 'Users',
    routeName: 'Users',
  },
];

export const COMPANY_EMPLOYEE_DETAILS_SUBMENU = (store) =>
  store.getters.selectedUser.id == 0
    ? [
        {
          title: 'Details',
          routeName: 'IAUserDetails',
        },
      ]
    : [
        {
          title: 'Details',
          routeName: 'IAUserDetails',
        },
        {
          title: 'Notifications',
          routeName: 'IAUserNotifications',
        },
        {
          title: 'Sync Logs',
          routeName: 'UserSyncLogs',
        },
      ];

export const FORM_TEMPLATE_SUB_MENU = (store) =>
  store.getters.selectedFormTemplate.id == 0
    ? [
        {
          title: 'Form Builder',
          routeName: 'PublicFormTemplateDetail',
        },
      ]
    : [
        {
          title: 'Form Builder',
          routeName: 'PublicFormTemplateDetail',
        },
        {
          title: 'Labels',
          routeName: 'PublicFormTemplateLabels',
        },
        {
          title: 'Preview',
          routeName: 'PublicFormTemplatePreview',
        },
      ];

export const PUBLIC_DOCUMENT_SUB_MENU = (store) =>
  store.getters.selectedDocument.id == 0
    ? [
        {
          title: 'General',
          routeName: 'PublicDocumentDetails',
        },
      ]
    : [
        {
          title: 'General',
          routeName: 'PublicDocumentDetails',
        },
        {
          title: 'Labels',
          routeName: 'PublicDocumentLabels',
        },
      ];

export const REPORTS_SUB_MENU = [
  {
    title: 'Web Login Audits',
    routeName: 'WebLoginAudits',
  },
  {
    title: 'Document Download Audits',
    routeName: 'DocumentDownloadAudits',
  },
];
