<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    content-class="my-custom-dialog"
  >
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row class="ma-1">
          <span> Switch Company </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-list :disabled="loading">
          <v-list-item
            v-for="company in companies"
            :key="company.id"
            @click="switchToCompany(company)"
            :disabled="disableActive(company)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-if="company.isHome" color="msaBlue" class="mr-2">
                  mdi-home-account
                </v-icon>
                {{ company.name }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon color="grey lighten-1">mdi-menu-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="msaBlue white--text" @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CompaniesInGroupDialog',
  data() {
    return {
      loading: false,
      dialog: false,
      companies: [],
    };
  },
  methods: {
    close() {
      this.companies = [];
      this.loading = false;
      this.dialog = false;
    },
    show() {
      this.dialog = true;
      this.getCompanies();
    },
    disableActive(company) {
      return company.id == this.$store.getters.user.companyId;
    },
    switchToCompany(company) {
      this.loading = true;
      const params = {
        companyId: company.id,
        loaderText: 'Switching to company...',
      };
      const url = 'switch-to-company?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          const user = {
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            username: response.data.username,
            email: response.data.email,
            type: response.data.type,
            companyName: response.data.companyName,
          };

          if (user.type != this.$constants.USER_TYPES.INTERNAL_ADMIN) {
            user.companyId = response.data.companyId;
            user.isSafetyRep = response.data.isSafetyRep;
            user.isManager = response.data.isManager;
            user.isSupervisor = response.data.isSupervisor;
            user.isGroupAdministrator = response.data.isGroupAdministrator;
            user.isPhantomUser = response.data.isPhantomUser;
            user.isSchedulerEnabled = response.data.isSchedulerEnabled;
            user.is1LifeMobileSettingEnabled =
              response.data.is1LifeMobileSettingEnabled;
          }
          this.$store.commit('invalidateStore');
          this.$store.commit('updateUser', user);
          this.$store.commit('updateJwtToken', response.data.token);

          // web admin and employee redirect
          this.$store.commit('updateSelectedCompany', {
            id: user.companyId,
            name: user.companyName,
            status: 1,
          });
          this.close();

          // the reload params is used to refresh the page for rendering the menu properly
          if (this.$route.name != 'UserHome') {
            this.$router.replace({
              name: 'UserHome',
              params: { reload: true },
            });
          } else {
            this.$router.go();
          }

          this.$emit('reloadLogo');
        })
        .catch((error) => {
          this.close();
          return error;
        });
    },
    getCompanies() {
      const params = {
        companyId: this.$store.getters.user.companyId,
        loaderText: 'Loading companies...',
      };
      const url = 'companies-in-same-group?format=json';
      return this.$axios
        .post(url, params)
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>
<style scoped>
* >>> .my-custom-dialog {
  align-self: flex-start;
}
</style>
