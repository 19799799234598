<template>
  <v-img
    contain
    max-height="100px"
    max-width="300px"
    min-width="200px"
    aspect-ratio="1"
    :src="url"
    v-if="notInternalAdmin && isSessionValid"
  >
  </v-img>
</template>

<script>
import axios from 'axios';
export default {
  name: 'MenuCompanyLogo',
  watch: {
    notInternalAdmin(val) {
      if (val && this.isSessionValid) {
        this.getLogo();
      }
    },
  },
  computed: {
    notInternalAdmin() {
      return (
        this.$store.getters.user.type !=
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
    isSessionValid() {
      return (
        this.$store.getters.user != '' && this.$store.getters.jwtToken != ''
      );
    },
  },
  data() {
    return {
      url: '',
    };
  },
  methods: {
    getLogo() {
      const params = {
        jwtToken: this.$store.getters.jwtToken,
      };

      const promise = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      });
      promise
        .post('download-organization-logo?format=json', params, {
          withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
        })
        .then((response) => {
          if (response.data != '') {
            this.url = 'data:image/jpeg;base64,' + response.data;
          }
        })
        .catch();
    },
  },
  mounted() {
    if (this.notInternalAdmin && this.isSessionValid) {
      this.getLogo();
    }
  },
};
</script>

<style></style>
