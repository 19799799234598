import * as constants from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import imageCompression from 'browser-image-compression';

const isImage = (mimeType) => mimeType.includes('image');

const validateMimeType = (mimeType, acceptedMimeTypes) => {
  if (!acceptedMimeTypes.includes(mimeType) || !mimeType) {
    throw new TypeError('Invalid file type.');
  }
};

const validateFileSize = (fileSize, maxFileSize) => {
  let maxSizeText = maxFileSize / 1024 / 1024 + 'MB';
  if (maxFileSize / 1024 / 1024 < 1) {
    maxSizeText = maxFileSize / 1024 + 'KB';
  }

  let errorMessage = `File size cannot exceed ${maxSizeText}.`;

  if (fileSize > maxFileSize) {
    throw new RangeError(errorMessage);
  }
};

const splitFilenameByExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return { name: filename, extension: '' };
  }
  const name = filename.substring(0, lastDotIndex);
  const extension = filename.substring(lastDotIndex + 1);
  return { name: name, extension: extension };
};

const validateFilename = (filename) => {
  if (!filename) {
    throw new TypeError('Invalid filename.');
  }
  const filenameInfo = splitFilenameByExtension(filename);

  if (!filenameInfo.extension.length) {
    throw new TypeError('File extension is required.');
  }
  if (filenameInfo.name.length > constants.MAX_FILENAME_LENGTH) {
    throw new RangeError(
      `Filename cannot exceed ${constants.MAX_FILENAME_LENGTH} characters.`,
    );
  }
};

const resizeImage = (imageFile) => {
  if (!imageFile) {
    return '';
  }
  const options = {
    maxSizeMB: 5,
    useWebWorker: true,
    maxWidthOrHeight: 1920,
  };
  return imageCompression(imageFile, options);
};

export default {
  install(Vue) {
    const helpers = {
      /*
        Checks if a date in the format 2022-02-22 is a valid date.
      */
      isValidDate(dateStr) {
        if (!dateStr) {
          return true;
        }
        dateStr = dateStr.trim();
        if (dateStr == '') {
          return true;
        }
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateStr.match(regex) === null) {
          return false;
        }
        const date = new Date(dateStr);

        const timestamp = date.getTime();
        if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
          return false;
        }
        return date.toISOString().startsWith(dateStr);
      },
      //This function is used so that the filters do not detect a change with only spaces.
      fixFilterSpaces(original, filters) {
        const result = JSON.parse(JSON.stringify(filters));
        for (const key in original) {
          if (Array.isArray(original[key])) {
            if (filters[key] == null) {
              result[key] = [];
            }
          }
          if (typeof original[key] == 'string') {
            if (filters[key] == null || filters[key].trim().length == 0)
              result[key] = '';
          }
        }
        return result;
      },
      serializeFilter(filters, original) {
        let result = {};

        // since argument [original] is a new one, we add compatibility here
        if (original == undefined) {
          result = JSON.parse(JSON.stringify(filters));
          for (const key in result) {
            if (Array.isArray(filters[key])) {
              result[key] = result[key].toString();
            }
          }
        } else {
          Object.keys(original).forEach((key) => {
            if (key in filters == false) {
              // Keys missing in the changed filter object count as no-change from the original
              filters[key] = original[key];
            }
            if (Array.isArray(filters[key])) {
              // for array values, we sort them first then convert to string to compare
              if (
                filters[key].sort().toString() !=
                original[key].sort().toString()
              ) {
                result[key] = filters[key].toString();
              }
            } else {
              if (filters[key].toString() != original[key].toString()) {
                result[key] = filters[key].toString();
              }
            }
          });
        }
        return result;
      },
      deserializeFilters(query, filters) {
        const result = JSON.parse(JSON.stringify(filters));

        for (const key in filters) {
          const originalValue = filters[key];
          const queryValue = query[key];

          if (queryValue == undefined) {
            result[key] = originalValue;
            continue;
          }

          if (Array.isArray(originalValue)) {
            if (queryValue.length) {
              const queryValueArr = queryValue.split(',');
              result[key] = queryValueArr.map((value) => {
                if (isNaN(parseInt(value))) {
                  return value;
                } else {
                  return parseInt(value);
                }
              });
            } else {
              result[key] = originalValue;
            }
            continue;
          }

          if (typeof originalValue == 'string' && queryValue != '') {
            result[key] = queryValue;
            continue;
          }

          if (queryValue != `${originalValue}`) {
            result[key] = parseInt(queryValue);
          }
        }
        return result;
      },
      serializeOptions(options, defaultSortBy, defaultSortDesc) {
        const result = {};

        if (parseInt(options.itemsPerPage) != 20) {
          result.itemsPerPage = parseInt(options.itemsPerPage);
        }

        if (parseInt(options.page) != 1) {
          result.page = parseInt(options.page);
        }

        result.sortBy = options.sortBy.length
          ? options.sortBy[0]
          : defaultSortBy;

        if (options.sortDesc.length) {
          result.sortDesc = options.sortDesc[0];
        } else {
          // when options sort desc is empty and default sort desc is true
          // on the data table component, the arrow disappears at the moment
          // however, we want sorting at all time, so we will skip this status
          // then the next sort desc option is false
          // or when default sort desc is not passed in
          // this is for back supporting code calling this method with just one parameter passed in
          // we use false for sort desc
          if (defaultSortDesc == true || defaultSortDesc == undefined) {
            result.sortDesc = false;
          } else {
            result.sortDesc = defaultSortDesc;
          }
        }
        return result;
      },
      deserializeOptions(query, defaultSortBy, defaultSortDesc) {
        return {
          page: parseInt(query.page) || constants.PAGINATION.DEFAULT_PAGE,
          itemsPerPage:
            parseInt(query.itemsPerPage) ||
            constants.PAGINATION.DEFAULT_PERPAGE,
          sortBy: query.sortBy ? [query.sortBy] : [defaultSortBy],
          sortDesc:
            query.sortDesc != undefined
              ? [JSON.parse(query.sortDesc)]
              : [defaultSortDesc != undefined ? defaultSortDesc : false],
        };
      },
      isGuid(value) {
        const guidRegex =
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return guidRegex.test(value);
      },
      createGuid() {
        return uuidv4();
      },
      convertDateToUTC(date) {
        let dayMonthYear = date.split('-');
        let dateTime = new Date();
        dateTime.setMilliseconds(0);
        dateTime.setHours(0, 0, 0);
        dateTime.setYear(dayMonthYear[0]);
        dateTime.setMonth(dayMonthYear[1] - 1);
        dateTime.setDate(dayMonthYear[2]);
        return new Date(dateTime).toISOString();
      },
      getLocalToUTCTime(sourceDate) {
        if (sourceDate) {
          var offset = new Date(sourceDate).getTimezoneOffset();
          sourceDate = new Date(sourceDate);
          return new Date(sourceDate.getTime() + offset * 60000)
            .toISOString()
            .replace('T', ' ')
            .replace('Z', '');
        }
      },

      /**
       * Global validation rules for date fields.
       * For use with the :rules attribute of vuetify's v-text-field
       * Refer to the Tech Manual for the desired behavior of this function
       * (https://docs.google.com/document/d/1kmugkg7aBzRcE4gpuz5aGjFw4GkbsKD_/edit?usp=sharing&ouid=110865377121391146381&rtpof=true&sd=true)
       *
       * @param  {String} date The date to be validated
       * @return {String} Validation error message if validation fails
       * @return {Boolean} true if date passes our validation rules
       */
      validateDate(date) {
        if (!date || date.trim() == '') {
          // If date is a required field, chain an additional rule to enforce it
          return true;
        }
        date = date.trim();
        if (date.match(/^\d{4}-\d{2}-\d{2}$/) === null) {
          return 'Date must be in YYYY-MM-DD format';
        }
        // Using Luxon, as Date.parse() has an inconsistent implementation across browsers for invalid dates.
        const datetime = DateTime.fromISO(date);
        if (!datetime.isValid) {
          // Example: 2000-44-44 is an invalid date, even though it's in the YYYY-MM-DD format
          return 'Invalid date';
        }
        if (datetime < DateTime.fromISO(constants.DATE_SELECTOR_RANGE.MIN)) {
          return `Date cannot be lower than ${constants.DATE_SELECTOR_RANGE.MIN}`;
        }
        if (datetime >= DateTime.fromISO(constants.DATE_SELECTOR_RANGE.MAX)) {
          return `Date must be lower than ${constants.DATE_SELECTOR_RANGE.MAX}`;
        }
        return true;
      },

      validateEmail(email, required = true, getAmbiguousMessage = false) {
        const invalid = 'Invalid email address.';
        const _email = email || '';

        if (getAmbiguousMessage && _email.trim() == '' && required) {
          return 'Email is required';
        }

        if (_email.trim() == '') {
          if (required) {
            return 'Email is required for this account type.';
          }
          return true;
        }
        //could not get these cases to work with regular expressions
        if (_email.indexOf('.@') != -1) {
          return invalid;
        }
        if (_email.indexOf('..') != -1) {
          return invalid;
        }
        // https://emailregex.com/
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(_email.trim())) {
          return invalid;
        }
        return true;
      },
      isValidEmail(email) {
        if (!email) {
          return true;
        }
        //could not get these cases to work with regular expressions
        if (email.indexOf('.@') != -1) {
          return false;
        }
        if (email.indexOf('..') != -1) {
          return false;
        }
        // https://emailregex.com/
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(email.trim())) {
          return false;
        }
        return true;
      },
      // validates a positive number with up to 2 decimal places
      validatePositiveNumber(value) {
        if (value === '' || value === null) {
          return true; // Allow empty input
        }
        const regex =
          /^(?!0+(?:\.0+)?$)(?:(?:[1-9]\d{0,2}|0)(?:\.\d{1,2})?|999\.99)$/;
        return (
          regex.test(value) ||
          'Please enter a positive number greater than 0 and less than 1000 with up to 2 decimal places.'
        );
      },
      validatePhone(phone, required = false) {
        const _phone = phone || '';
        const cleanPhone = _phone.trim();
        if (!cleanPhone || cleanPhone.trim() == '') {
          if (required) {
            return 'Phone number is required.';
          }
          return true;
        }
        const pattern =
          /^([0-9]{1})?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!pattern.test(cleanPhone)) {
          return 'Invalid phone number.';
        }
        return true;
      },
      isValidPhone(phone) {
        if (!phone) {
          return true;
        }
        const pattern =
          /^([0-9]{1})?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!pattern.test(phone.trim())) {
          return false;
        }
        return true;
      },
      /** Global validation rules for usernames. For use with Vuetify text-field :rules.
       * @param {String} username The username to validate.
       * @returns {String|Boolean} Returns a validation message if validation fails, and returns true if validation passes.
       */
      validateUsername(username) {
        if (!username) {
          return true; // Defer validation to next rule in the chain
        }
        if (username.length < 4) {
          return 'Username must be at least 4 characters';
        }
        if (username.length > 64) {
          return 'Username cannot exceed 64 characters';
        }
        const regex = /^[^<>+=,&^#*():|"/;` %\\]*$/;
        if (!regex.test(username)) {
          return `Username cannot contain the following characters: < > + = , & ^ # * ( ) : | " / ; \` % \\`;
        }

        return true;
      },
      /** Simple function for getting a Boolean pass/fail on a username.
       * @param {String} username The username to check
       * @returns {Boolean} True if username is valid. False otherwise.
       */
      isValidUsername(username) {
        const result = this.validateUsername(username);
        if (typeof result == 'string') {
          return false;
        } else {
          return true;
        }
      },
      validateTitle(title) {
        const _title = title || '';

        if (_title.length > 64) {
          return 'Title cannot exceed 64 characters.';
        }

        return true;
      },
      validateRelation(relation, required = false) {
        const _relation = relation || '';
        // Lower than 4 in the username validation to allow for Mom/Dad, etc.
        if (_relation.length == 0 && required) {
          return 'Relationship required';
        }

        if (_relation.length > 64) {
          return 'Relationship cannot exceed 64 characters.';
        }

        return true;
      },
      validatePassword(password, isRequired = true) {
        const _password = password || '';
        if (_password.length == 0 && !isRequired) {
          return true;
        }

        if (_password.length == 0 && isRequired) {
          return 'Password is required.';
        }

        if (_password.length < 6 && _password.length > 0) {
          return 'Password needs to be at least 6 characters.';
        }

        if (_password.length > 64) {
          return 'Password cannot exceed 64 characters.';
        }

        if (!/[A-Z]/.test(_password)) {
          return 'Password must contain at least one upper case letter.';
        }

        if (!/[a-z]/.test(_password)) {
          return 'Password must contain at least one lower case letter.';
        }

        if (!/[0-9]/.test(_password)) {
          return 'Password must contain at least one number.';
        }

        if (/[#]/.test(_password)) {
          return 'Password must not contain #.';
        }

        return true;
      },
      validatePasswordAll(password) {
        const _password = password || '';
        let result = '';
        if (_password.length == 0) {
          result += '<li>Password is required.</li>';
        }

        if (_password.length < 6) {
          result += '<li>Password needs to be at least 6 characters.</li>';
        }

        if (_password.length > 64) {
          result += '<li>Password cannot exceed 64 characters.</li>';
        }

        if (!/[A-Z]/.test(_password)) {
          result +=
            '<li>Password must contain at least one upper case letter.</li>';
        }

        if (!/[a-z]/.test(_password)) {
          result +=
            '<li>Password must contain at least one lower case letter.</li>';
        }

        if (!/[0-9]/.test(_password)) {
          result += '<li>Password must contain at least one number.</li>';
        }

        if (/[#]/.test(_password)) {
          result += '<li>Password must not contain #.</li>';
        }
        if (result.length > 0) {
          result = `<ul class="text-left">${result}</ul>`;
        }
        return result;
      },
      required(value, field) {
        const _value = value || '';
        if (isNaN(_value)) {
          if (_value.trim().length < 1) {
            return `${field} required.`;
          }
        } else {
          if (_value < 1) {
            return `${field} required.`;
          }
        }

        return true;
      },
      formatDateTimeToUTC(date, time = '23:00') {
        return DateTime.fromISO(new Date(`${date} ${time}`).toISOString(), {
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }).toUTC();
      },
      getToday() {
        const today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
      },
      isDeepEqual(object1, object2) {
        const result = { result: true, differences: 0 };
        const isObject = (object) => {
          return (
            object != null && object != undefined && typeof object == 'object'
          );
        };

        for (var key of Object.keys(object1)) {
          const value1 = object1[key];
          const value2 = object2[key];

          const isObjects = isObject(value1) && isObject(value2);

          if (isObjects) {
            const childResult = this.isDeepEqual(value1, value2);
            if (!childResult.result) {
              result.differences += 1;
              result.result = false;
            }
          } else {
            if (value1 != value2) {
              result.differences += 1;
              result.result = false;
            }
          }
        }
        return result;
      },
      getRouteQueryForString(routeQueries, fieldName) {
        let result = '';

        const routeValue = routeQueries[fieldName];
        if (routeValue != undefined && routeValue.trim().length > 0) {
          result = routeValue;
        }

        return result;
      },
      defaultTableOptions(sortBy, sortDesc) {
        if (sortBy != undefined && sortDesc != undefined) {
          return {
            sortBy: [sortBy],
            sortDesc: [sortDesc],
            page: constants.PAGINATION.DEFAULT_PAGE,
            itemsPerPage: constants.PAGINATION.DEFAULT_PERPAGE,
          };
        }

        return {
          page: constants.PAGINATION.DEFAULT_PAGE,
          itemsPerPage: constants.PAGINATION.DEFAULT_PERPAGE,
        };
      },
      downloadFile(responseData, filename) {
        const url = URL.createObjectURL(new Blob([responseData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      },
      previewFileInNewWindow(responseData) {
        const fileURL = URL.createObjectURL(responseData);
        window.open(fileURL);
      },
      // detect whether there is an URL in the provided string and makes it a link
      linkify(text) {
        const urlRegex =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
        return text.replace(urlRegex, function (url) {
          return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
      },
      safeUrl(url) {
        const x = new RegExp('^(http|https)://');
        if (!x.test(url)) {
          return `https://${url}`;
        }

        return url;
      },
      /**
       * Takes a filter object, and returns a copy of it without any keys that
       * have 'null' as its value.
       * For use with Vuetify, as it frequently uses 'null'. This is handy for
       * stripping nulls from cleared input controls, like v-text-field.
       */
      toFilterWithoutNulls(filter) {
        const filterWithoutNulls = {};
        for (const key in filter) {
          if (filter[key] !== null) {
            filterWithoutNulls[key] = filter[key];
          }
        }
        return filterWithoutNulls;
      },
      async processFile(
        file,
        acceptedMimeTypes,
        checkFilename = false,
        // this is a temporary solution requested in https://www.pivotaltracker.com/story/show/188179924
        maxFileSize = constants.MAX_FILE_SIZE['100MB'],
      ) {
        if (!file) {
          return '';
        }

        if (!acceptedMimeTypes.length) {
          throw new Error('No accepted file types provided.');
        }

        validateMimeType(file.type, acceptedMimeTypes);
        validateFileSize(file.size, maxFileSize);
        if (checkFilename) {
          validateFilename(file.name);
        }
        if (isImage(file.type)) {
          try {
            return await resizeImage(file);
          } catch (error) {
            // this usually happened with a corrupted file
            throw new TypeError('Unknown error occurred. Please try again.');
          }
        }
        return file;
      },
      splitFilenameByExtension,
      resizeImage,
    };
    // Fun will happen here
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};
