<template>
  <span id="cht-menu-nav">
    <CompaniesInGroupDialog
      ref="companiesInGroupDialog"
      @reloadLogo="logoReloadKey++"
    />
    <!-- desktop -->
    <v-app-bar height="auto" class="white" id="app-bar" elevation="0">
      <v-container fluid class="pl-0">
        <v-row align="center">
          <v-col v-if="!isInternalAdmin" align="left" class="shrink">
            <MenuCompanyLogo :key="logoReloadKey" />
          </v-col>

          <v-col v-if="!isInternalAdmin" align="center" class="grow">
            <v-row>
              <v-col
                v-if="isGroupAdmin"
                cols="12"
                class="msaBlue--text text-h5 font-weight-bold"
                @click="showCompanies"
                style="cursor: pointer"
              >
                {{ companyName }}
                <v-icon class="ml-n2" large color="msaBlue">
                  mdi-chevron-down
                </v-icon>
              </v-col>
              <v-col
                v-else
                cols="12"
                class="msaBlue--text text-h5 font-weight-bold"
              >
                {{ companyName }}
              </v-col>
              <v-col>
                <!-- This is left here for future use. -->
              </v-col>
            </v-row>
          </v-col>

          <v-spacer v-if="isInternalAdmin"></v-spacer>

          <v-col class="shrink">
            <HelpDialog />
          </v-col>

          <v-col class="shrink">
            <MenuMisc />
          </v-col>

          <v-col cols="12" align="center" class="py-0" :style="navbarStyles">
            <MenuNav />
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <div :class="{ 'ml-5': !isMobile, 'mr-5': !isMobile }" class="pt-0">
      <v-container fluid class="pt-0">
        <v-row class="pt-0">
          <v-col class="pt-0">
            <MenuBreadcrumb :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </span>
</template>
<script>
import CompaniesInGroupDialog from '@/components/Menus/CompaniesInGroupDialog.vue';
import MenuCompanyLogo from '@/components/Menus/MenuCompanyLogo.vue';
import MenuBreadcrumb from '@/components/Menus/MenuBreadcrumb.vue';
import HelpDialog from '@/components/Menus/HelpDialog.vue';
import MenuMisc from '@/components/Menus/MenuMisc.vue';
import MenuNav from '@/components/Menus/MenuNav.vue';

export default {
  name: 'MenuBar',
  components: {
    CompaniesInGroupDialog,
    MenuCompanyLogo,
    MenuBreadcrumb,
    HelpDialog,
    MenuMisc,
    MenuNav,
  },
  data() {
    return {
      navbarStyles: {
        background: 'linear-gradient(to bottom,  #0a5fb1 0%,#093f79 100%)',
      },
      mobileMenu: false,
      logoReloadKey: 9999,
    };
  },
  computed: {
    companyName() {
      return this.$store.getters.user.companyName;
    },
    isInternalAdmin() {
      return this.$store.getters.user.type == 2;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isGroupAdmin() {
      return (
        this.$store.getters.user.type != 2 &&
        this.$store.getters.user.isGroupAdministrator == 1
      );
    },
  },
  methods: {
    showCompanies() {
      this.$refs.companiesInGroupDialog.show();
    },
  },
};
</script>
<style>
#app-bar .v-toolbar__content {
  padding: 0px !important;
}
</style>
