<template>
  <v-autocomplete
    :value="value"
    outlined
    dense
    :multiple="multiple"
    :placeholder="label"
    :label="label"
    :append-icon="appendIcon"
    flat
    :hide-details="hideDetails"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :clearable="clearable"
    chips
    :return-object="returnObject"
    :deletable-chips="deletableChips"
    :disabled="disabled"
    :hint="hint"
    :rules="rules"
    :persistent-hint="persistentHint"
    :prepend-icon="prependIcon"
    :error="error"
    :loading="loading"
    @change="$emit('change', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <template v-slot:[`selection`]="{ item, index }">
      <v-chip
        color="msaBlue"
        dark
        :close="deletableChips"
        small
        @click:close="
          $emit(
            'change',
            value.filter((_, i) => i !== index),
          )
        "
      >
        <div class="overflow-x-hidden text-truncate">
          {{ isObject(item) ? item[itemText] : item }}
        </div>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'CustomizedAutoComplete',
  props: {
    value: {
      required: true,
    },
    appendIcon: {
      required: false,
      default: '$dropdown',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    prependIcon: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isObject() {
      return (item) => typeof item === 'object';
    },
  },
};
</script>
