import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../plugins/store';
import { USER_TYPES } from '../constants';
import * as USER from '../userMenus.js';
import * as IA from '../internalAdminMenus.js';

Vue.use(VueRouter);
const isUserLoggedIn = () => store.getters.user != '';
const isInternalAdmin = () =>
  isUserLoggedIn() && store.getters.user.type == USER_TYPES.INTERNAL_ADMIN;

const internalAdminRoutes = {
  path: '/internal-admin',
  name: 'IARoot',
  redirect: { name: 'CompanyList' },
  component: () => import('../views/RootView.vue'),
  beforeEnter: (from, to, next) => {
    if (!isInternalAdmin()) {
      router.push({ name: 'AccessDenied' });
    } else {
      next();
    }
  },
  children: [
    {
      path: 'user-profile',
      name: 'InternalAdminProfile',
      component: () =>
        import('../views/InternalAdmin/InternalAdminProfilePage.vue'),
    },
    // IA - Companies
    {
      path: 'companies',
      name: 'CompanyList',
      meta: {
        parentIndex: 0,
        crumbs: [
          {
            text: 'Companies',
            disabled: true,
          },
          {
            text: 'Company List',
            disabled: true,
          },
        ],
        submenu: IA.COMPANIES_SUBMENU,
      },
      component: () => import('../views/InternalAdmin/CompanyList.vue'),
    },
    {
      path: 'companies/:companyId',
      name: 'CompanyRoot',
      redirect: { name: 'CompanyDetail' },
      component: () => import('../views/RootView.vue'),
      meta: {
        crumbs: (store) => [
          {
            text: 'Companies',
            disabled: true,
          },
          {
            text: 'Company List',
            disabled: false,
            exact: true,
            to: { name: 'CompanyList' },
          },
          {
            text: store.getters.selectedCompany.name,
            disabled: false,
            exact: true,
            to: { name: 'CompanyDetail' },
          },
        ],
        submenu: (store) => IA.COMPANY_DETAILS_SUBMENU(store),
      },
      children: [
        {
          path: 'detail',
          name: 'CompanyDetail',
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Details',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Company/CompanyDetails.vue'),
        },
        {
          path: 'users',
          name: 'IACompanyUsers',
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Users',
                disabled: true,
              },
            ],
          },
          component: () => import('../views/CompanyUsersPage.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
        {
          path: 'edit-users',
          name: 'IAMassEditUsers',
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Users',
                disabled: true,
              },
            ],
          },
          component: () => import('../views/UserMassEditPage.vue'),
        },
        {
          path: 'users/:id/details/',
          name: 'IAUserDetails',
          meta: {
            parentIndex: 0,
            crumbs: (store) => [
              {
                text: `Users`,
                disabled: false,
                exact: true,
                to: { name: 'IACompanyUsers' },
              },
              {
                text: `${store.getters.selectedUser.firstName} ${store.getters.selectedUser.lastName}`,
                disabled: false,
                exact: true,
                to: { name: 'IAUserDetails' },
              },
              {
                text: 'Details',
                disabled: true,
              },
            ],
            submenu: (store) => IA.COMPANY_EMPLOYEE_DETAILS_SUBMENU(store),
          },
          component: () => import('../views/UserDetailsRevampedPage.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
        {
          path: 'users/:id/notifications/',
          name: 'IAUserNotifications',
          meta: {
            parentIndex: 0,
            crumbs: (store) => [
              {
                text: `Users`,
                disabled: false,
                exact: true,
                to: { name: 'IACompanyUsers' },
              },
              {
                text: `${store.getters.selectedUser.firstName} ${store.getters.selectedUser.lastName}`,
                disabled: false,
                exact: true,
                to: { name: 'IAUserDetails' },
              },
              {
                text: 'Notifications',
                disabled: true,
              },
            ],
            submenu: (store) => IA.COMPANY_EMPLOYEE_DETAILS_SUBMENU(store),
          },
          component: () => import('../views/UserNotificationsPage.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
        {
          path: 'users/:id/sync-logs/:guid',
          name: 'SyncDetails',
          meta: {
            parentIndex: 0,
            crumbs: (store) => [
              {
                text: `Users`,
                disabled: false,
                exact: true,
                to: { name: 'IACompanyUsers' },
              },
              {
                text: `${store.getters.selectedUser.firstName} ${store.getters.selectedUser.lastName}`,
                disabled: false,
                exact: true,
                to: { name: 'IAUserDetails' },
              },
              {
                text: 'Sync Logs',
                disabled: true,
              },
              {
                text: `${store.getters.selectedSyncGuid}`,
                disabled: true,
              },
            ],
            submenu: (store) => IA.COMPANY_EMPLOYEE_DETAILS_SUBMENU(store),
          },
          component: () => import('../views/SyncLogsPage.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
        {
          path: 'users/:id/sync-logs/',
          name: 'UserSyncLogs',
          meta: {
            parentIndex: 0,
            crumbs: (store) => [
              {
                text: `Users`,
                disabled: false,
                exact: true,
                to: { name: 'IACompanyUsers' },
              },
              {
                text: `${store.getters.selectedUser.firstName} ${store.getters.selectedUser.lastName}`,
                disabled: false,
                exact: true,
                to: { name: 'IAUserDetails' },
              },
              {
                text: 'Sync Logs',
                disabled: true,
              },
            ],
            submenu: (store) => IA.COMPANY_EMPLOYEE_DETAILS_SUBMENU(store),
          },
          children: [
           
          ],
          component: () => import('../views/UserSyncLogsPage.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
          
        },
        {
          path: 'labels',
          name: 'CompanyLabels',
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Labels',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Company/CompanyLabels.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
        {
          path: 'notes',
          name: 'CompanyNotes',
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Notes',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Company/CompanyNotes.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
        {
          path: 'sync-logs',
          name: 'IACompanySyncLogs',
          component: () => import('../views/CompanySyncLogsPage.vue'),
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Sync Logs',
                disabled: true,
              },
            ],
          },
        },
        {
          path: 'form-templates',
          name: 'IACompanyFormTemplates',
          meta: {
            parentIndex: 0,
            crumbs: [
              {
                text: 'Form Templates',
                disabled: true,
              },
            ],
          },
          component: () =>
            import(
              '../views/InternalAdmin/Company/IACompanyFormTemplatesPage.vue'
            ),
          beforeEnter: (from, to, next) => {
            if (from.params.companyId <= 0) {
              router.push({ name: 'CompanyList' });
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: 'company-groups',
      name: 'IACompanyGroups',
      component: () => import('../views/InternalAdmin/CompanyGroups.vue'),
      meta: {
        parentIndex: 0,
        crumbs: [
          {
            text: 'Companies',
            disabled: true,
          },
          {
            text: 'Groups',
            disabled: true,
          },
        ],
        submenu: IA.COMPANIES_SUBMENU,
      },
    },
    {
      path: 'company-groups/:id',
      name: 'IACompanyGroupRoot',
      redirect: { name: 'IAGroupDetail' },
      component: () => import('../views/RootView.vue'),
      children: [
        {
          path: 'detail',
          name: 'IAGroupDetail',
          meta: {
            parentIndex: 0,
            crumbs: (store) => [
              {
                text: 'Companies',
                disabled: true,
              },
              {
                text: 'Groups',
                disabled: true,
              },
              {
                text: store.getters.selectedGroup.name,
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/CompanyGroup/GroupDetail.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedGroup.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: 'users',
      name: 'Users',
      component: () => import('../views/InternalAdmin/UserList.vue'),
      meta: {
        parentIndex: 0,
        crumbs: [
          {
            text: 'Companies',
            disabled: true,
          },
          {
            text: 'Users',
            disabled: true,
          },
        ],
        submenu: IA.COMPANIES_SUBMENU,
      },
    },
    // IA - News Feed
    {
      path: 'news-feeds',
      name: 'NewsFeeds',
      component: () => import('../views/InternalAdmin/NewsFeeds.vue'),
      meta: {
        parentIndex: 1,
        crumbs: [
          {
            text: 'News Feed',
            disabled: true,
          },
        ],
      },
    },
    // IA - Resources
    {
      path: 'resources',
      name: 'PublicDocuments',
      component: () => import('../views/InternalAdmin/PublicDocuments.vue'),
      meta: {
        parentIndex: 2,
        crumbs: [
          {
            text: 'Resources',
            disabled: true,
          },
        ],
      },
    },
    {
      path: 'resources/:id',
      name: 'PublicDocumentRoot',
      redirect: { name: 'PublicDocumentDetails' },
      component: () => import('../views/RootView.vue'),
      meta: {
        parentIndex: 2,
        crumbs: (store) => [
          {
            text: 'Resources',
            disabled: false,
            exact: true,
            to: { name: 'PublicDocuments' },
          },
          {
            text: store.getters.selectedDocument.name,
            disabled: true,
          },
        ],
        submenu: (store) => IA.PUBLIC_DOCUMENT_SUB_MENU(store),
      },
      children: [
        {
          path: 'detail',
          name: 'PublicDocumentDetails',
          meta: {
            parentIndex: 2,
            crumbs: [
              {
                text: 'Details',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/PublicDocumentDetails.vue'),
        },
        {
          path: 'labels',
          name: 'PublicDocumentLabels',
          meta: {
            parentIndex: 2,
            crumbs: [
              {
                text: 'Labels',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/PublicDocumentLabels.vue'),
        },
      ],
    },
    // IA - Training
    {
      path: 'training-courses',
      name: 'IaTrainingCourses',
      component: () => import('../views/InternalAdmin/TrainingCourses.vue'),
      meta: {
        parentIndex: 3,
        crumbs: [
          {
            text: 'Training',
            disabled: true,
          },
        ],
      },
    },
    // IA - Forms
    {
      path: 'public-form-templates',
      name: 'PublicFormTemplates',
      component: () => import('../views/FormTemplatesPage.vue'),
      meta: {
        parentIndex: 4,
        crumbs: [
          {
            text: 'Forms',
            disabled: true,
          },
        ],
      },
    },
    {
      path: 'public-form-templates/:id',
      name: 'PublicFormTemplateRoot',
      redirect: { name: 'PublicFormTemplateDetail' },
      component: () => import('../views/RootView.vue'),
      meta: {
        parentIndex: 4,
        crumbs: (store) => [
          {
            text: 'Forms',
            disabled: false,
            exact: true,
            to: { name: 'PublicFormTemplates' },
          },
          {
            text: store.getters.selectedFormTemplate.name,
            disabled: true,
          },
        ],
      },
      children: [
        {
          path: 'detail',
          name: 'PublicFormTemplateDetail',
          meta: {
            parentIndex: 4,
            crumbs: [
              {
                text: 'Details',
                disabled: true,
              },
            ],
            submenu: (store) => IA.FORM_TEMPLATE_SUB_MENU(store),
          },
          component: () => import('../views/FormTemplateDetail.vue'),
        },
        {
          path: 'preview',
          name: 'PublicFormTemplatePreview',
          meta: {
            parentIndex: 4,
            crumbs: [
              {
                text: 'Preview',
                disabled: true,
              },
            ],
            submenu: (store) => IA.FORM_TEMPLATE_SUB_MENU(store),
          },
          component: () => import('../views/FormDetailsPage.vue'),
          beforeEnter: (from, to, next) => {
            from.params.isPreview = 1;
            next();
          },
        },
        {
          path: 'labels',
          name: 'PublicFormTemplateLabels',
          meta: {
            parentIndex: 4,
            crumbs: [
              {
                text: 'Labels',
                disabled: true,
              },
            ],
            submenu: (store) => IA.FORM_TEMPLATE_SUB_MENU(store),
          },
          component: () =>
            import('../views/InternalAdmin/PublicFormTemplateLabels.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedFormTemplate.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
      ],
    },
    // IA - Labels
    {
      path: 'labels',
      name: 'IALabels',
      component: () => import('../views/InternalAdmin/LabelsPage.vue'),
      meta: {
        parentIndex: 5,
        crumbs: [
          {
            text: 'Labels',
            disabled: true,
          },
        ],
      },
    },
    {
      path: 'labels/:id',
      name: 'LabelRoot',
      redirect: { name: 'LabelDetail' },
      component: () => import('../views/RootView.vue'),
      meta: {
        parentIndex: 5,
        crumbs: (store) => [
          {
            text: 'Labels',
            disabled: false,
            exact: true,
            to: { name: 'IALabels' },
          },
          {
            text: store.getters.selectedLabel.name,
            disabled: true,
          },
        ],
        submenu: (store) => IA.LABELS_SUBMENU(store),
      },
      children: [
        {
          path: 'detail',
          name: 'LabelDetail',
          meta: {
            parentIndex: 5,
            crumbs: [
              {
                text: 'Details',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Label/LabelDetail.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedLabel.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
        {
          path: 'labeled-companies',
          name: 'LabelCompanies',
          meta: {
            parentIndex: 5,
            crumbs: [
              {
                text: 'Companies',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Label/LabelCompanies.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedLabel.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
        {
          path: 'labeled-documents',
          name: 'LabelDocuments',
          meta: {
            parentIndex: 5,
            crumbs: [
              {
                text: 'Documents',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Label/LabelDocuments.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedLabel.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
        {
          path: 'labeled-courses',
          name: 'LabelCourses',
          meta: {
            parentIndex: 5,
            crumbs: [
              {
                text: 'Courses',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Label/LabelCourses.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedLabel.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
        {
          path: 'labeled-form-templates',
          name: 'LabelFormTemplates',
          meta: {
            parentIndex: 5,
            crumbs: [
              {
                text: 'Form Templates',
                disabled: true,
              },
            ],
          },
          component: () =>
            import('../views/InternalAdmin/Label/LabelFormTemplates.vue'),
          beforeEnter: (from, to, next) => {
            if (from.params.id != store.getters.selectedLabel.id) {
              router.push({ name: 'AccessDenied' });
            } else {
              next();
            }
          },
        },
      ],
    },
    // IA - Blacklisted Emails
    {
      path: 'blacklisted-emails',
      name: 'BlacklistedEmails',
      component: () => import('../views/InternalAdmin/BlacklistedEmails.vue'),
      meta: {
        crumbs: [
          {
            text: 'Blacklisted Emails',
            disabled: true,
          },
        ],
        parentIndex: 6,
      },
    },
    // IA - Requirement Templates
    {
      path: 'requirement-templates',
      name: 'RequirementTemplates',
      component: () =>
        import('../views/InternalAdmin/RequirementTemplates.vue'),
      meta: {
        crumbs: [
          {
            text: 'Requirement Templates',
            disabled: true,
          },
        ],
        parentIndex: 7,
      },
    },
    // IA - Reports
    {
      path: 'web-login-audits',
      name: 'WebLoginAudits',
      component: () => import('../views/InternalAdmin/WebLoginAuditsPage.vue'),
      meta: {
        parentIndex: 8,
        crumbs: [
          {
            text: 'Reports',
            disabled: true,
          },
          {
            text: 'Web Login Audits',
            disabled: true,
          },
        ],
        submenu: IA.REPORTS_SUB_MENU,
      },
    },
    {
      path: 'document-download-audits',
      name: 'DocumentDownloadAudits',
      component: () =>
        import('../views/InternalAdmin/DocumentDownloadAudits.vue'),
      meta: {
        parentIndex: 8,
        crumbs: [
          {
            text: 'Reports',
            disabled: true,
          },
          {
            text: 'Document Download Audits',
            disabled: true,
          },
        ],
        submenu: IA.REPORTS_SUB_MENU,
      },
    },
  ],
};

const userRoutes = {
  path: '/',
  name: 'UserRoot',
  component: () => import('../views/RootView.vue'),
  beforeEnter: (to, from, next) => {
    if (!isUserLoggedIn()) {
      router.replace({ name: 'UserLogin', params: { redirect: to } });
    } else if (isInternalAdmin()) {
      router.replace({ name: 'UserLogin' });
    }
    next();
  },
  children: [
    {
      path: '/home',
      name: 'UserHome',
      meta: {
        parentIndex: 0,
      },
      component: () => import('../views/HomePage.vue'),
    },
    {
      path: '/news',
      name: 'UserNews',
      meta: {
        parentIndex: 0,
        crumbs: [
          {
            text: 'News',
            disabled: true,
          },
        ],
      },
      component: () => import('../views/NewsPage.vue'),
    },
    {
      path: '/admin',
      name: 'AdminRoot',
      meta: {
        parentIndex: 1,
      },
      redirect: { name: 'CompanySettings' },
      component: () => import('../views/RootView.vue'),
      children: [
        {
          path: 'company-settings',
          name: 'CompanySettings',
          meta: {
            parentIndex: 1,
            submenu: USER.COMPANY_SETTINGS_SUB_MENU,
          },
          component: () => import('../views/CompanySettingsPage.vue'),
        },
        {
          path: 'company-resource-settings',
          name: 'CompanyResourceSettings',
          meta: {
            parentIndex: 1,
            submenu: USER.COMPANY_SETTINGS_SUB_MENU,
          },
          component: () => import('../views/CompanySettingsResourcePage.vue'),
        },
        {
          path: 'company-input-field-settings',
          name: 'CompanyInputFieldSettings',
          meta: {
            parentIndex: 1,
            submenu: USER.COMPANY_SETTINGS_SUB_MENU,
          },
          component: () => import('../views/CompanySettingsInputFieldPage.vue'),
        },
        {
          path: 'mobile-settings',
          name: 'MobileSettings',
          meta: {
            parentIndex: 1,
            submenu: USER.MOBILE_APP_SUB_MENU,
          },
          component: () => import('../views/MobileSettingsPage.vue'),
        },
        {
          path: 'sync-logs',
          name: 'CompanySyncLogs',
          meta: {
            parentIndex: 1,
            submenu: USER.MOBILE_APP_SUB_MENU,
          },
          component: () => import('../views/CompanySyncLogsPage.vue'),
        },
        {
          path: 'company-profile',
          name: 'CompanyProfile',
          component: () => import('../views/CompanyProfilePage.vue'),
        },
        {
          path: 'users/:id',
          name: 'CompanyUserRoot',
          redirect: { name: 'UserDetails' },
          component: () => import('../views/RootView.vue'),
          meta: {
            crumbs: (store) => [
              {
                text: 'Users',
                disabled: false,
                exact: true,
                to: { name: 'CompanyUsers' },
              },
              {
                text: `${store.getters.selectedUser.firstName} ${store.getters.selectedUser.lastName}`,
                disabled: false,
                exact: true,
                to: { name: 'UserDetails' },
              },
            ],
          },
          children: [
            {
              path: 'details',
              name: 'UserDetails',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Details',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/UserDetailsRevampedPage.vue'),
            },
            {
              path: 'notifications',
              name: 'UserNotifications',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Notificationns',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/UserNotificationsPage.vue'),
            },
            {
              path: 'projects',
              name: 'UserProjects',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Projects',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/UserProjectsPage.vue'),
            },
            {
              path: 'tasks',
              name: 'UserTasks',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Tasks',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/UserTasksPage.vue'),
            },
            {
              path: 'tasks/:groupId/new',
              name: 'UserTaskNew',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Tasks',
                    disabled: false,
                    exact: true,
                    to: { name: 'UserTasks' },
                  },
                  {
                    text: 'New',
                    disabled: true,
                  },
                ],
              },
              component: () => import('../views/TaskDetailPage.vue'),
            },
            {
              path: 'tasks/:taskId/detail',
              name: 'UserTaskDetail',
              meta: {
                parentIndex: 1,
                crumbs: (store) => [
                  {
                    text: 'Tasks',
                    disabled: false,
                    exact: true,
                    to: { name: 'UserTasks' },
                  },
                  {
                    text: store.getters.selectedTaskName,
                    disabled: true,
                  },
                ],
              },
              component: () => import('../views/TaskDetailPage.vue'),
            },
            {
              path: 'forms',
              name: 'UserForms',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Forms',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/FormsPage.vue'),
            },
            {
              path: 'documents',
              name: 'UserAssignedDocuments',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Documents',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/AssignedDocumentsPage.vue'),
            },
            {
              path: 'training-records',
              name: 'UserTrainingRecords',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Training',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/TrainingRecordsPage.vue'),
            },
            {
              path: 'notes',
              name: 'UserNotes',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Notes',
                    disabled: true,
                  },
                ],
                submenu: (store) => USER.USER_DETAILS_SUB_MENU(store),
              },
              component: () => import('../views/UserNotesPage.vue'),
            },
          ],
        },
        {
          path: 'users',
          name: 'CompanyUsers',
          meta: {
            parentIndex: 1,
            submenu: USER.USERS_SUB_MENU,
          },
          component: () => import('../views/CompanyUsersPage.vue'),
        },
        {
          path: 'edit-users',
          name: 'MassEditUsers',
          meta: {
            parentIndex: 1,
            submenu: USER.USERS_SUB_MENU,
          },
          component: () => import('../views/UserMassEditPage.vue'),
        },
        {
          path: 'groups',
          name: 'CompanyGroups',
          meta: {
            parentIndex: 1,
            submenu: USER.USERS_SUB_MENU,
          },
          component: () => import('../views/CompanyGroupsPage.vue'),
        },
        {
          path: 'groups/:id',
          name: 'CompanyGroupRoot',
          redirect: { name: 'CompanyGroupedEmployees' },
          component: () => import('../views/RootView.vue'),
          meta: {
            crumbs: (store) => [
              {
                text: 'Groups',
                disabled: false,
                exact: true,
                to: { name: 'CompanyGroups' },
              },
              {
                text: `${store.getters.selectedLabel.name}`,
                disabled: false,
                exact: true,
                to: { name: 'CompanyGroupedEmployees' },
              },
            ],
            submenu: USER.LABEL_SUB_MENU,
          },
          children: [
            {
              path: 'employees',
              name: 'CompanyGroupedEmployees',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Employees',
                    disabled: true,
                  },
                ],
              },
              component: () =>
                import('../views/CompanyGroupedEmployeesPage.vue'),
            },
            {
              path: 'documents',
              name: 'CompanyGroupedDocuments',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Documents',
                    disabled: true,
                  },
                ],
              },
              component: () =>
                import('../views/CompanyGroupedDocumentsPage.vue'),
            },
            {
              path: 'courses',
              name: 'CompanyGroupedCourses',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Courses',
                    disabled: true,
                  },
                ],
              },
              component: () => import('../views/CompanyGroupedCoursesPage.vue'),
            },
            {
              path: 'task-groups',
              name: 'CompanyGroupedTaskGroups',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Task Groups',
                    disabled: true,
                  },
                ],
              },
              component: () =>
                import('../views/CompanyGroupedTaskGroupsPage.vue'),
            },
          ],
        },
        {
          path: 'contacts',
          name: 'CompanyContacts',
          meta: {
            parentIndex: 1,
            submenu: USER.USERS_SUB_MENU,
          },
          component: () => import('../views/CompanyContactsPage.vue'),
        },
        {
          path: 'contacts/:id',
          name: 'ContactDetails',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Contacts',
                disabled: false,
                exact: true,
                to: { name: 'CompanyContacts' },
              },
              {
                text: store.getters.selectedContact.fullname,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/ContactDetailsPage.vue'),
        },
        {
          path: 'contacts-import',
          name: 'CompanyContactsImport',
          meta: {
            parentIndex: 1,
            crumbs: [
              {
                text: 'Contacts',
                disabled: false,
                exact: true,
                to: { name: 'CompanyContacts' },
              },
              {
                text: 'Import Contacts',
                disabled: true,
              },
            ],
          },
          component: () => import('../views/CompanyContactsImportPage.vue'),
        },
        {
          path: 'projects',
          name: 'CompanyProjects',
          meta: {
            parentIndex: 1,
          },
          component: () => import('../views/CompanyProjectsPage.vue'),
        },
        {
          path: 'projects/:id',
          name: 'ProjectDetails',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Projects',
                disabled: false,
                exact: true,
                to: { name: 'CompanyProjects' },
              },
              {
                text: store.getters.selectedProject.name,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/ProjectDetailsPage.vue'),
        },
        {
          path: 'projects-import',
          name: 'CompanyProjectsImport',
          meta: {
            parentIndex: 1,
            crumbs: [
              {
                text: 'Projects',
                disabled: false,
                exact: true,
                to: { name: 'CompanyProjects' },
              },
              {
                text: 'Import Projects',
                disabled: true,
              },
            ],
          },
          component: () => import('../views/CompanyProjectsImportPage.vue'),
        },
        {
          path: 'library',
          name: 'CompanyDocumentLibrary',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_DOCUMENTS_SUB_MENU,
          },
          component: () => import('../views/DocumentLibraryPage.vue'),
        },
        {
          path: 'assigned-documents',
          name: 'CompanyAssignedDocuments',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_DOCUMENTS_SUB_MENU,
          },
          component: () => import('../views/AssignedDocumentsPage.vue'),
        },
        {
          path: 'assigned-documents/:id',
          name: 'CompanyAssignedDocumentDetails',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Assigned Documents',
                disabled: false,
                exact: true,
                to: { name: 'CompanyAssignedDocuments' },
              },
              {
                text: store.getters.selectedAssignedDocument.title,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/AssignedDocumentDetailsPage.vue'),
        },
        {
          path: 'resources',
          name: 'CompanyResources',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_DOCUMENTS_SUB_MENU,
          },
          component: () => import('../views/CompanyResourcesPage.vue'),
        },
        {
          path: 'form-templates',
          name: 'FormTemplates',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_FORMS_SUB_MENU,
          },
          component: () => import('../views/FormTemplatesPage.vue'),
        },
        {
          path: 'form-templates/:id',
          name: 'FormTemplateRoot',
          redirect: { name: 'FormTemplateDetail' },
          component: () => import('../views/RootView.vue'),
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Form Templates',
                disabled: false,
                exact: true,
                to: { name: 'FormTemplates' },
              },
              {
                text: store.getters.selectedFormTemplate.name,
                disabled: true,
              },
            ],
            submenu: (store) => USER.FORM_TEMPLATE_SUB_MENU(store),
          },
          children: [
            {
              path: 'form-builder',
              name: 'FormTemplateDetail',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Form Builder',
                    disabled: true,
                  },
                ],
              },
              component: () => import('../views/FormTemplateDetail.vue'),
            },
            {
              path: 'preview',
              name: 'FormTemplatePreview',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Preview',
                    disabled: true,
                  },
                ],
              },
              component: () => import('../views/FormDetailsPage.vue'),
              beforeEnter: (from, to, next) => {
                from.params.isPreview = 1;
                next();
              },
            },
          ],
        },
        {
          path: 'forms',
          name: 'CompanyForms',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_FORMS_SUB_MENU,
          },
          component: () => import('../views/FormsPageRevamped.vue'),
        },
        {
          path: 'forms/:id',
          name: 'CompanyFormDetails',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Filled Out Forms',
                disabled: false,
                exact: true,
                to: { name: 'CompanyForms' },
              },
              {
                text: store.getters.selectedFormInstance.name,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/FormDetailsPage.vue'),
        },
        {
          path: 'form-tags',
          name: 'FormTags',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_FORMS_SUB_MENU,
          },
          component: () => import('../views/FormTagsPage.vue'),
        },
        {
          path: 'form-tags/:id',
          name: 'FormTagDetail',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Form Tags',
                disabled: false,
                exact: true,
                to: { name: 'FormTags' },
              },
              {
                text: store.getters.selectedTagName,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/FormTagDetailPage.vue'),
        },
        {
          path: 'mass-archive-forms',
          name: 'MassArchiveForms',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_FORMS_SUB_MENU,
          },
          component: () => import('../views/MassArchiveFormsPage.vue'),
        },
        {
          path: 'training-records',
          name: 'CompanyTrainingRecords',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_TRAINING_SUB_MENU,
          },
          component: () => import('../views/TrainingRecordLibraryPage.vue'),
        },
        {
          path: 'training-records/:id',
          name: 'CompanyTrainingRecordDetails',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Training Records',
                disabled: false,
                exact: true,
                to: { name: 'CompanyTrainingRecords' },
              },
              {
                text: store.getters.selectedTrainingRecord.name,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/TrainingRecordDetailsPage.vue'),
        },
        {
          path: 'task-templates',
          name: 'TaskTemplates',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_TRAINING_SUB_MENU,
          },
          component: () => import('../views/TaskTemplatesPage.vue'),
        },
        {
          path: 'task-templates/:id',
          name: 'TaskTemplateDetail',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Tasks',
                disabled: false,
                exact: true,
                to: { name: 'TaskTemplates' },
              },
              {
                text: store.getters.selectedTaskTemplateName,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/TaskTemplateDetailPage.vue'),
        },
        {
          path: 'task-templates/new',
          name: 'TaskTemplateNew',
          meta: {
            parentIndex: 1,
            crumbs: (store) => [
              {
                text: 'Tasks',
                disabled: false,
                exact: true,
                to: { name: 'TaskTemplates' },
              },
              {
                text: store.getters.selectedTaskTemplateName,
                disabled: true,
              },
            ],
          },
          component: () => import('../views/TaskTemplateDetailPage.vue'),
        },
        {
          path: 'training-groups',
          name: 'TrainingGroups',
          meta: {
            parentIndex: 1,
            submenu: USER.ADMIN_TRAINING_SUB_MENU,
          },
          component: () => import('../views/CompanyGroupsPage.vue'),
        },
        {
          path: 'training-groups/:id',
          name: 'CompanyTrainingGroupRoot',
          redirect: { name: 'CompanyTrainingGroupedEmployees' },
          component: () => import('../views/RootView.vue'),
          meta: {
            crumbs: (store) => [
              {
                text: 'Groups',
                disabled: false,
                exact: true,
                to: { name: 'TrainingGroups' },
              },
              {
                text: `${store.getters.selectedLabel.name}`,
                disabled: false,
                exact: true,
                to: { name: 'CompanyTrainingGroupedEmployees' },
              },
            ],
            submenu: USER.TRAINING_LABEL_SUB_MENU,
          },
          children: [
            {
              path: 'employees',
              name: 'CompanyTrainingGroupedEmployees',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Employees',
                    disabled: true,
                  },
                ],
              },
              component: () =>
                import('../views/CompanyGroupedEmployeesPage.vue'),
              beforeEnter: (from, to, next) => {
                if (from.params.id != store.getters.selectedLabel.id) {
                  router.push({ name: 'AccessDenied' });
                } else {
                  next();
                }
              },
            },
            {
              path: 'documents',
              name: 'CompanyTrainingGroupedDocuments',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Documents',
                    disabled: true,
                  },
                ],
              },
              component: () =>
                import('../views/CompanyGroupedDocumentsPage.vue'),
              beforeEnter: (from, to, next) => {
                if (from.params.id != store.getters.selectedLabel.id) {
                  router.push({ name: 'AccessDenied' });
                } else {
                  next();
                }
              },
            },
            {
              path: 'courses',
              name: 'CompanyTrainingGroupedCourses',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Courses',
                    disabled: true,
                  },
                ],
              },
              component: () => import('../views/CompanyGroupedCoursesPage.vue'),
              beforeEnter: (from, to, next) => {
                if (from.params.id != store.getters.selectedLabel.id) {
                  router.push({ name: 'AccessDenied' });
                } else {
                  next();
                }
              },
            },
            {
              path: 'task-groups',
              name: 'CompanyTrainingGroupedTaskGroups',
              meta: {
                parentIndex: 1,
                crumbs: [
                  {
                    text: 'Task Groups',
                    disabled: true,
                  },
                ],
              },
              component: () =>
                import('../views/CompanyGroupedTaskGroupsPage.vue'),
              beforeEnter: (from, to, next) => {
                if (from.params.id != store.getters.selectedLabel.id) {
                  router.push({ name: 'AccessDenied' });
                } else {
                  next();
                }
              },
            },
          ],
        },
        {
          path: 'training-records-report',
          name: 'TrainingRecordsReport',
          meta: {
            parentIndex: 1,
            submenu: USER.REPORTS_SUB_MENU,
          },
          component: () => import('../views/TrainingRecordsReportPage.vue'),
        },
        {
          path: 'tasks-report',
          name: 'TrainingTasksReport',
          meta: {
            parentIndex: 1,
            submenu: USER.REPORTS_SUB_MENU,
          },
          component: () => import('../views/TrainingTasksReportPage.vue'),
        },
        {
          path: 'schedule-users-report',
          name: 'ScheduleUsersReport',
          meta: {
            parentIndex: 1,
            submenu: USER.REPORTS_SUB_MENU,
          },
          component: () => import('../views/ScheduleUsersReportPage.vue'),
        },
        {
          path: 'corrective-actions-report',
          name: 'CorrectiveActionsReport',
          meta: {
            parentIndex: 1,
            submenu: USER.REPORTS_SUB_MENU,
          },
          component: () => import('../views/CorrectiveActionsReportPage.vue'),
        },
      ],
    },
    {
      path: '/document-library',
      name: 'UserDocumentLibrary',
      meta: {
        parentIndex: 2,
        submenu: USER.DOCUMENTS_SUB_MENU,
      },
      component: () => import('../views/DocumentLibraryPage.vue'),
    },
    {
      path: '/forms',
      name: 'Forms',
      meta: {
        parentIndex: 2,
        submenu: USER.DOCUMENTS_SUB_MENU,
      },
      component: () => import('../views/FormsPage.vue'),
    },
    {
      path: '/forms/:id',
      name: 'FormDetails',
      meta: {
        parentIndex: 2,
        crumbs: (store) => [
          {
            text: 'Forms',
            disabled: false,
            exact: true,
            to: { name: 'Forms' },
          },
          {
            text: store.getters.selectedFormInstance.name,
            disabled: true,
          },
        ],
      },
      component: () => import('../views/FormDetailsPage.vue'),
    },
    {
      path: '/tasks',
      name: 'Tasks',
      meta: {
        parentIndex: 3,
        submenu: USER.TRAINING_SUB_MENU,
      },
      component: () => import('../views/TasksPage.vue'),
    },
    {
      path: '/tasks/:id',
      name: 'TaskDetail',
      meta: {
        parentIndex: 3,
        crumbs: (store) => [
          {
            text: 'Tasks',
            disabled: false,
            exact: true,
            to: { name: 'Tasks' },
          },
          {
            text: store.getters.selectedTaskName,
            disabled: true,
          },
        ],
      },
      component: () => import('../views/TaskDetailPage.vue'),
    },
    {
      path: '/training-records',
      name: 'TrainingRecords',
      meta: {
        parentIndex: 3,
        submenu: USER.TRAINING_SUB_MENU,
      },
      component: () => import('../views/TrainingRecordsPage.vue'),
    },
    {
      path: '/user-profile',
      name: 'UserProfile',
      component: () => import('../views/UserDetailsRevampedPage.vue'),
      meta: {
        submenu: USER.PROFILE_SUB_MENU,
      },
    },
    {
      path: '/notifications',
      name: 'UserProfileNotifications',
      component: () => import('../views/UserNotificationsPage.vue'),
      meta: {
        submenu: USER.PROFILE_SUB_MENU,
      },
    },
    {
      path: '/requiring-my-attention',
      name: 'RequiringMyAttention',
      component: () => import('../views/RequiringMyAttention.vue'),
      meta: {
        parentIndex: 2,
        submenu: USER.DOCUMENTS_SUB_MENU,
      },
    },
    {
      path: 'assigned-documents',
      name: 'AssignedDocuments',
      meta: {
        parentIndex: 2,
        submenu: USER.DOCUMENTS_SUB_MENU,
      },
      component: () => import('../views/AssignedDocumentsPage.vue'),
    },
    {
      path: 'assigned-documents/:id',
      name: 'AssignedDocumentDetails',
      meta: {
        parentIndex: 2,
        crumbs: (store) => [
          {
            text: 'Assigned Documents',
            disabled: false,
            exact: true,
            to: { name: 'AssignedDocuments' },
          },
          {
            text: store.getters.selectedAssignedDocument.title,
            disabled: true,
          },
        ],
      },
      component: () => import('../views/AssignedDocumentDetailsPage.vue'),
    },
    {
      path: 'drafts',
      name: 'FormDrafts',
      meta: {
        parentIndex: 2,
        submenu: USER.DOCUMENTS_SUB_MENU,
      },
      component: () => import('../views/FormsPage.vue'),
    },
  ],
};

const routes = [
  {
    path: '/login',
    alias: ['/'],
    name: 'UserLogin',
    component: () => import('../views/LoginPage.vue'),
  },
  {
    path: '/sign-up/:companyCypher',
    alias: ['/'],
    name: 'SignUp',
    component: () => import('../views/SignUpPage.vue'),
  },
  {
    path: '/yw-sign-up',
    alias: ['/'],
    name: 'YWSignUp',
    component: () => import('../views/YoungWorkerSignUpPage.vue'),
  },
  {
    path: '/forgot-password',
    alias: ['/'],
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPasswordPage.vue'),
  },
  {
    path: '/new-password',
    alias: ['/'],
    name: 'NewPassword',
    component: () => import('../views/NewPasswordPage.vue'),
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import('../views/AccessDenied.vue'),
  },
  internalAdminRoutes,
  userRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // always scroll to top left
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.commit('abortRequests');
  next();
});

export { router };
