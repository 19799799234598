<template>
  <v-menu offset-y close-on-click>
    <template v-slot:activator="{ on, attrs, value }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        outlined
        color="msaBlue"
        large
        v-blur
        min-height="60px"
      >
        <v-row no-gutters>
          <v-col cols="12" class="text-capitalize text-caption">
            Hello, {{ fullname }}
          </v-col>
          <v-col class="font-weight-black text-capitalize text-body-2">
            your account
            <v-icon v-if="!value" small> mdi-chevron-up </v-icon>
            <v-icon v-if="value" small> mdi-chevron-down </v-icon>
          </v-col>
        </v-row>
      </v-btn>
    </template>

    <v-list nav>
      <v-list-item v-if="isWebAdmin" @click="goToCompanyProfile()">
        <v-icon class="pr-2"> mdi-office-building </v-icon>
        Company Profile
      </v-list-item>
      <v-list-item @click="goToUser()">
        <v-icon class="pr-2"> mdi-account </v-icon>
        User Profile
      </v-list-item>
      <v-list-item @click="logout()">
        <v-icon class="pr-2"> mdi-exit-to-app </v-icon>
        Logout
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'MenuMisc',

  computed: {
    isInternalAdmin() {
      return this.$store.getters.user.type == 2;
    },
    isWebAdmin() {
      return this.$store.getters.user.type == 1;
    },
    fullname() {
      return `${this.$store.getters.user.firstName} ${this.$store.getters.user.lastName}`;
    },
  },
  methods: {
    logout() {
      this.$router.replace(
        { name: 'UserLogin', params: { logout: true } },
        () => {
          this.$axios
            .post('logout?format=json', { loaderText: 'loading...' })
            .then(() => {
              this.$store.commit('stopSessionCounter');
              this.$store.commit('invalidateStore');
            });
        },
      );
    },
    goToUser() {
      if (this.$store.getters.user.isPhantomUser) {
        this.$root.showMessage(
          'Cannot Edit User Profile',
          'Please switch to your home company to edit your profile.',
          () => false,
          null,
          'OK',
          null,
        );
        return;
      }

      if (this.isInternalAdmin) {
        if (this.$route.name != 'InternalAdminProfile') {
          this.$router.push({
            name: 'InternalAdminProfile',
          });
        }
      } else {
        if (this.$route.name != 'UserProfile') {
          this.$router.push({
            name: 'UserProfile',
          });
        }
      }
    },
    goToCompanyProfile() {
      if (this.$route.name != 'CompanyProfile') {
        this.$router.push({
          name: 'CompanyProfile',
        });
      }
    },
  },
};
</script>

<style></style>
