<template>
  <v-app>
    <dialog-window ref="dialogWindow"></dialog-window>
    <v-snackbar v-model="showSnack" timeout="2000">
      {{ snackText }}
    </v-snackbar>
    <template>
      <div class="text-center">
        <v-overlay
          :value="showLoader"
          z-index="99999"
          class="text-h6"
          opacity="0.8"
          ><v-progress-circular
            indeterminate
            color="#3564ac"
          ></v-progress-circular>
          <v-spacer></v-spacer>
          {{ loaderText }}
        </v-overlay>
      </div>
    </template>
    <template>
      <v-dialog v-model="showError" persistent width="300">
        <v-card>
          <v-card-title class="headline red--text">Error</v-card-title>
          <v-card-text>
            An error has occurred, please reload the page and try again. If the
            problem persists please contact 1Life Workplace Solution at:
            1-866-223-7374 or 1-204-231-5433.
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1">
              <v-btn color="red darken-1" text @click="showError = false">
                OK
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="showTimeoutWarning" width="300px" persistent>
        <v-card>
          <v-card-title class="warning white--text">
            Timeout Warning
          </v-card-title>
          <v-card-text class="mt-4">
            Your session will timeout in {{ timeLeft }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="msaBlue white--text" @click="keepAlive">
              Keep me logged in
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <MenuBar v-if="showMenu && userLoggedIn" :key="reloadMenu" />

    <v-main>
      <div
        v-if="isAuthenticated"
        :class="{
          'ml-8': !isMobile && $route.name != 'UserLogin',
          'mr-8': !isMobile && $route.name != 'UserLogin',
        }"
      >
        <router-view :key="$route.fullPath" />
      </div>
      <div v-else>
        <router-view :key="$route.fullPath" />
      </div>
    </v-main>

    <v-footer class="msaBlue--text" id="cht-footer">
      <div :style="{ width: '100%', 'text-align': 'center' }">
        &copy; {{ new Date().getFullYear() }} 1Life Workplace Safety Solutions
        Ltd.
      </div>
    </v-footer>
  </v-app>
</template>
<script>
import DialogWindow from '@/components/DialogWindow';
import MenuBar from '@/components/Menus/MenuBar.vue';
import { DateTime } from 'luxon';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    DialogWindow,
    MenuBar,
  },
  watch: {
    '$route.fullPath'(val) {
      const excludedPaths = [
        'login',
        'forgot-password',
        'new-password',
        'access-denied',
        'sign-up',
      ];
      if (excludedPaths.some((path) => val.includes(path))) {
        return;
      }
      const now = DateTime.now();
      const lastCheck = this.$store.getters.rmaLastCheck;

      if (lastCheck == null) {
        this.checkRMA();
        return;
      }
      const diff = now.diff(lastCheck, 'seconds').seconds;
      if (diff > this.checkRMAInterval || val == '/home') {
        this.checkRMA();
      }
    },
    '$store.getters.sessionTimer.count'(count) {
      if (count < 1 && this.$store.getters.sessionTimer.enabled) {
        this.autoLogOut();
      }
    },
  },
  computed: {
    isAuthenticated() {
      const excludedRoutes = [
        'UserLogin',
        'ForgotPassword',
        'NewPassword',
        'AccessDenied',
        'SignUp',
        'YWSignUp',
      ];
      if (
        this.$route.name != null &&
        excludedRoutes.some((routeName) => this.$route.name.includes(routeName))
      ) {
        return false;
      }
      return true;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showTimeoutWarning() {
      if (
        this.$store.getters.sessionTimer.enabled &&
        this.$store.getters.sessionTimer.count <
          this.$store.getters.sessionTimer.warning &&
        this.$store.getters.sessionTimer.count != 0
      ) {
        return true;
      }
      return false;
    },
    timeLeft() {
      return this.$store.getters.sessionTimer.display;
    },
    showMenu() {
      switch (this.$route.name) {
        case 'AccessDenied':
        case 'SignUp':
        case 'YWSignUp':
        case 'UserLogin':
          return false;
        case 'PasswordReset':
          return false;
        case 'NewPassword':
          return false;
        case '':
          return false;
        default:
          return true;
      }
    },
    menuEnabled() {
      if (this.$store.getters.jwtToken) {
        return true;
      } else {
        return false;
      }
    },
    userLoggedIn() {
      return this.$store.getters.user != '';
    },
  },
  data() {
    return {
      showError: false,
      disableAutoError: false,
      showLoader: false,
      showLoaderDefault: false,
      showSnack: false,
      snackText: '',
      loaderText: '',
      loaderDefaultText: 'Loading...',
      pageDescription: '',
      reloadMenu: this.$helpers.createGuid(),
      checkRMAInterval: 3,
    };
  },
  methods: {
    restoreSessionTimer() {
      const routeNames = [
        'UserLogin',
        'AccessDenied',
        'SignUp',
        'YWSignUp',
        'PasswordReset',
        'NewPassword',
        'ForgotPassword',
      ];
      if (
        (!routeNames.includes(this.$route.name) || this.$route.name == null) &&
        this.$store.getters.sessionTimer.timestamp > 0
      ) {
        let sessionWindow =
          this.$store.getters.sessionTimer.timestamp +
          this.$store.getters.sessionTimer.timeout * 1000;
        if (sessionWindow < Date.now()) {
          //if the session window has passed then log the user out
          if (this.$store.getters.jwtToken != '') {
            this.autoLogOut();
          }
        } else {
          //if the session window has not passed then start the session timer
          this.$store.dispatch('startSessionCounter');
        }
      } else {
        //if the page is loaded or refreshed in is in the list then stop the session timer
        this.$store.commit('stopSessionCounter');
      }
    },
    autoLogOut() {
      const url = 'logout?format=json';
      const promise = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      });
      promise
        .post(url, { loaderText: 'Login out...' })
        .then(() => {
          this.$store.commit('stopSessionCounter');
          this.$store.commit('invalidateStore');
          this.$router
            .replace({
              name: 'UserLogin',
              query: { autoLogout: true },
            })
            .catch((error) => error);
        })
        .catch();
    },
    keepAlive() {
      this.$store.commit('resetSessionCounter');
      this.$store.commit('updateSessionTimestamp');
    },
    reloadLogo() {
      this.reloadMenu = this.$helpers.createGuid();
    },
    checkRMA() {
      if (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        return;
      }
      const url = 'check-requiring-my-attention?format=json';
      // Creating this axios instance is necessary here.
      // Using this.$axios will overwrite any other loaderText
      const promise = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        useWithCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      });
      promise
        .post(
          url,
          { jwtToken: this.$store.getters.jwtToken },
          {
            withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
          },
        )
        .then((response) => {
          this.$store.commit('updateRequiringMyAttention', response.data);
          this.$store.commit('updateRmaLastCheck', DateTime.now());
        })
        .catch((e) => {
          if (e.response.status == 401) {
            this.$store.commit('stopSessionCounter');
            this.$store.commit('invalidateStore');
            if (this.$route.name != 'UserLogin') {
              this.$router.replace({ name: 'UserLogin' });
            }
          }
          return e;
        });
    },
    abortRequests() {
      this.$store.commit('abortRequests');
    },
    toggleLoaderText(value, text = '') {
      this.showLoader = value;
      this.loaderText = text;
    },
  },
  created() {
    window.addEventListener('beforeunload', this.abortRequests);
    this.$showMessage = {};
    this.$refreshLogo = null;
    this.useWithCredentials = process.env.VUE_APP_USE_WITH_CREDENTIALS;

    this.$axios.interceptors.request.use((config) => {
      // this is used to cancel on going requests when route changes
      config.signal = this.$store.getters.abortSignal;
      const isFormData = config.data instanceof FormData;
      this.disableAutoError = false;
      if (
        (isFormData && config.data.get('disableAutoError')) ||
        config.data['disableAutoError']
      ) {
        this.disableAutoError = true;
      }
      this.showLoader =
        config.data['loaderText'] ||
        (isFormData && config.data.get('loaderText')) ||
        this.showLoaderDefault;
      this.loaderText =
        config.data['loaderText'] ||
        (isFormData && config.data.get('loaderText')) ||
        this.loaderDefaultText;

      config.withCredentials = this.useWithCredentials;
      config.data['jwtToken'] = this.$store.getters.jwtToken;
      config.data['tz'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.data['timezoneOffset'] = -new Date().getTimezoneOffset();
      this.keepAlive();
      return config;
    });
    this.$axios.interceptors.response.use(
      (response) => {
        this.showLoader = false;
        if (response.config.data instanceof FormData == false) {
          var snackText = JSON.parse(response.config.data)['snackText'] || '';
          if (snackText != '') {
            this.snackText = snackText;
            this.showSnack = true;
          }
        }

        return response;
      },
      (error) => {
        this.showLoader = false;

        // this means the request does not finish for some reason
        if (error.response === undefined) {
          return Promise.reject(error);
        }

        if (error.response.status == 401) {
          this.$store.commit('invalidateStore');
          this.$router.replace({ name: 'UserLogin' });
          return Promise.reject(error);
        }

        if (
          this.disableAutoError &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          //user asked for warnings to not be automatically handled
          return Promise.reject(error);
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          if (typeof error.response.data === 'object') {
            if (
              error.response.data instanceof FormData == false &&
              error.response.data instanceof Blob == false
            ) {
              this.$root.showMessage(
                error.response.data.title,
                error.response.data.message,
                () => false,
                null,
                'OK',
              );
            } else {
              // this part means the response type is FormData or Blob
              // which is usually for downloading a file.
              const reader = new FileReader();
              reader.addEventListener('loadend', (e) => {
                let text = '';
                try {
                  text = e.target.result.trim().substring(1).slice(0, -1);
                } catch (e) {
                  // do nothing
                }
                if (text == '') {
                  text = 'File not found.';
                }
                this.$root.showMessage(
                  'Error',
                  text,
                  () => false,
                  null,
                  'OK',
                  'NO',
                );
              });
              reader.readAsText(error.response.data);
            }
          } else {
            this.$root.showMessage(
              '',
              error.response.data,
              () => false,
              null,
              'OK',
            );
          }

          return Promise.reject(error);
        }

        this.showError = true;
        return Promise.reject(error.response);
      },
    );
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.abortRequests);
  },
  mounted() {
    this.$root.appVersion = process.env.PACKAGE_VERSION;
    this.$root.showMessage = this.$refs.dialogWindow.show;
    this.$root.toggleLoaderText = this.toggleLoaderText;
    this.$root.reloadLogo = this.reloadLogo;

    if (
      this.$route.name != 'UserLogin' &&
      this.$route.name != 'SignUp' &&
      this.$route.name != 'AccessDenied' &&
      this.$route.name != 'RequiringMyAttention' &&
      !this.$store.getters.isInternalAdmin &&
      this.$route.name
    ) {
      this.checkRMA();
    }
    this.restoreSessionTimer();
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .v-data-table .v-data-footer .v-data-footer__pagination,
  .v-data-table .v-data-footer .v-data-footer__select {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.v-expansion-panel-content__wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
#app {
  background: url('./assets/background.png') center center fixed !important;
  background-size: cover !important;
}

.colored-table-check-box .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}

.clickable {
  cursor: pointer;
  color: #3564ac;
}
</style>
