import Vue from 'vue';
import Vuex from 'vuex';
import { USER_TYPES, PAGINATION, SESSION_TIMER_CONFIG } from '@/constants';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const APP_NAME = 'cht_';

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => localStorage.getItem(APP_NAME + key),
        setItem: (key, value) => localStorage.setItem(APP_NAME + key, value),
        removeItem: (key) => localStorage.removeItem(APP_NAME + key),
      },
    }),
  ],
  state: {
    user: '',
    jwtToken: '',
    selectedGroup: {},
    selectedLabel: {},
    selectedFormInstance: {},
    selectedTagName: '',
    selectedTaskTemplateName: '',
    selectedTaskName: '',
    selectedUser: {
      id: -1,
      username: '',
      firstName: '',
      lastName: '',
    },
    crumbRefresh: 1,
    selectedCompany: {
      id: -1,
      name: '',
      status: true,
    },
    selectedPublicDocuments: [],
    selectedFormTemplate: null,
    selectedTrainingRecord: null,
    selectedProject: null,
    selectedContact: null,
    selectedSyncGuid: '',
    selectedAssignedDocument: null,
    selectedDocument: null,
    assignedDocumentSelected: [],
    groupedDocumentsSelected: [],
    groupedTaskGroupsSelected: [],
    groupedUsersSelected: [],
    groupedCoursesSelected: [],
    persistentSettings: {
      CompanyTrainingRecords: {
        expandedGroupId: -1,
        options: {
          page: PAGINATION.DEFAULT_PAGE,
          itemsPerPage: PAGINATION.DEFAULT_PERPAGE,
          sortBy: ['name'],
          sortDesc: [false],
        },
      },
      CompanyForms: {
        headers: [],
        filters: {},
        customPageView: {},
        page: PAGINATION.DEFAULT_PAGE,
      },
      TaskTemplates: {
        expandedGroupIds: [],
      },
      FormDrafts: {
        selectedDrafts: [],
      },
    },
    requiringMyAttention: 0,
    rmaLastCheck: null,
    sessionTimer: { ...SESSION_TIMER_CONFIG },
    usersSelected: [],
    abortController: new AbortController(),
  },
  actions: {
    async startSessionCounter({ state, commit }) {
      state.sessionTimer = { ...SESSION_TIMER_CONFIG };
      state.sessionTimer.enabled = true;
      state.sessionTimer.count = state.sessionTimer.timeout;
      while (state.sessionTimer.enabled && state.sessionTimer.count > 0) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (state.sessionTimer.enabled) {
          /*
        This chunk of code is similar to app.vue. 
        However this chunk checks for when the 
        computer comes back from sleep.
      */
          let sessionWindow =
            state.sessionTimer.timestamp + state.sessionTimer.timeout * 1000;
          if (sessionWindow < Date.now()) {
            if (state.jwtToken != '') {
              state.sessionTimer.count = -1;
              return;
            }
          }
        }

        if (state.sessionTimer.enabled) {
          commit('tickSessionCounter');
          //required to log user out if they minimized the window
          if (!document.hidden) {
            commit('updateSessionTimestamp');
          }
        }
      }
    },
  },
  mutations: {
    updateSessionTimestamp(state) {
      state.sessionTimer.timestamp = Date.now();
    },
    resetSessionCounter(state) {
      state.sessionTimer.count = state.sessionTimer.timeout;
    },
    stopSessionCounter(state) {
      state.sessionTimer.enabled = false;
      state.sessionTimer.count = 0;
      state.sessionTimer.timestamp = 0;
    },
    tickSessionCounter(state) {
      state.sessionTimer.count--;
      let minutes = parseInt(state.sessionTimer.count / 60, 10);
      let seconds = parseInt(state.sessionTimer.count % 60, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      state.sessionTimer.display = minutes + ':' + seconds;
    },
    updateCrumbRefresh(state) {
      state.crumbRefresh++;
    },
    updateSelectedCompany(state, value) {
      state.selectedCompany.id = value.id;
      state.selectedCompany.name = value.name;
      state.selectedCompany.status = value.status;
      state.selectedCompany.companyGroupId = value.companyGroupId;
    },
    updateSelectedUser(state, value) {
      state.selectedUser = value;
    },
    updateUsersSelected(state, value) {
      state.usersSelected = value;
    },
    updateSelectedLabel(state, value) {
      state.selectedLabel.id = value.id;
      state.selectedLabel.name = value.name;
      state.selectedLabel.description = value.description;
    },
    updateSelectedGroup(state, value) {
      state.selectedGroup = value;
    },
    updateUser(state, value) {
      state.user = value;
    },
    updateJwtToken(state, value) {
      state.jwtToken = value;
    },
    updateSelectedFormTemplate(state, value) {
      state.selectedFormTemplate = value;
    },
    updateSelectedTrainingRecord(state, value) {
      state.selectedTrainingRecord = value;
    },
    setSelectedTagName(state, value) {
      state.selectedTagName = value;
    },
    setSelectedTaskTemplateName(state, value) {
      state.selectedTaskTemplateName = value;
    },
    setSelectedTaskName(state, value) {
      state.selectedTaskName = value;
    },
    updateSelectedProject(state, value) {
      state.selectedProject = value;
    },
    updateSelectedContact(state, value) {
      state.selectedContact = value;
    },
    updateSelectedSyncGuid(state, value) {
      state.selectedSyncGuid = value;
    },
    updateSelectedFormInstance(state, value) {
      state.selectedFormInstance = value;
    },
    updateSelectedAssignedDocument(state, value) {
      state.selectedAssignedDocument = value;
    },
    updateGroupedCoursesSelected(state, value) {
      state.groupedCoursesSelected = value;
    },
    updateGroupedTaskGroupsSelected(state, value) {
      state.groupedTaskGroupsSelected = value;
    },
    updateGroupedUsersSelected(state, value) {
      state.groupedUsersSelected = value;
    },
    updateGroupedDocumentsSelected(state, value) {
      state.groupedDocumentsSelected = value;
    },
    updateSelectedDocument(state, value) {
      state.selectedDocument = value;
    },
    updateSelectedPublicDocuments(state, event) {
      // Used to clear on route leave
      if (!event.value && !event.items.length) {
        state.selectedPublicDocuments = [];
        return;
      }

      if (event.value) {
        const map = new Map();
        const concatArr = state.selectedPublicDocuments.concat(event.items);

        concatArr.forEach((doc) => {
          map.set(doc.id, doc); // Remove duplicates
        });

        state.selectedPublicDocuments = Array.from(map.values());
        return;
      }

      const idSetToRemove = new Set(
        event.items.map((doc) => {
          return doc.id;
        }),
      );
      state.selectedPublicDocuments = state.selectedPublicDocuments.filter(
        (doc) => !idSetToRemove.has(doc.id),
      );
    },
    invalidateStore(state) {
      state.user = '';
      state.jwtToken = '';
      state.selectedGroup = {};
      state.selectedLabel = {};
      state.selectedUser = {
        id: -1,
        username: '',
        firstName: '',
        lastName: '',
      };
      state.selectedCompany = {
        id: -1,
        name: '',
        status: true,
      };
      state.selectedPublicDocuments = [];
      state.selectedFormTemplate = null;
      state.selectedTrainingRecord = null;
      state.selectedProject = null;
      state.selectedContact = null;
      state.selectedSyncGuid = '',
      state.selectedAssignedDocument = null;
      state.selectedDocument = null;
      state.assignedDocumentSelected = [];
      state.groupedUsersSelected = [];
      state.groupedTaskGroupsSelected = [];
      state.groupedDocumentsSelected = [];
      state.groupedCoursesSelected = [];
      state.persistentSettings = {
        CompanyTrainingRecords: {
          expandedGroupId: -1,
          options: {
            page: PAGINATION.DEFAULT_PAGE,
            itemsPerPage: PAGINATION.DEFAULT_PERPAGE,
            sortBy: ['name'],
            sortDesc: [false],
          },
        },
        CompanyForms: {
          headers: [],
          filters: {},
          customPageView: {},
          page: PAGINATION.DEFAULT_PAGE,
        },
        TaskTemplates: {
          expandedGroupIds: [],
        },
        FormDrafts: {
          selectedDrafts: [],
        },
      };
      state.requiringMyAttention = 0;
      state.rmaLastCheck = null;
      state.usersSelected = [];
    },
    updateAssignedDocumentSelected(state, value) {
      state.assignedDocumentSelected = value;
    },

    updatePersistentSettings(state, payload) {
      state.persistentSettings[payload.key] = JSON.parse(
        JSON.stringify(payload.value),
      );
    },
    updateRequiringMyAttention(state, payload) {
      state.requiringMyAttention = payload;
    },
    updateRmaLastCheck(state, payload) {
      state.rmaLastCheck = payload;
    },
    abortRequests(state) {
      if (state.abortController.abort) {
        state.abortController.abort();
      }
      state.abortController = new AbortController();
    },
  },
  getters: {
    user: (state) => state.user,
    jwtToken: (state) => state.jwtToken,
    selectedLabel: (state) => state.selectedLabel,
    selectedUser: (state) => state.selectedUser,
    selectedGroup: (state) => state.selectedGroup,
    isInternalAdmin: (state) => state.user.type === USER_TYPES.INTERNAL_ADMIN,
    isWebAdmin: (state) => state.user.type === USER_TYPES.WEB_ADMIN,
    isNormalUser: (state) => state.user.type === USER_TYPES.NORMAL_USER,
    selectedCompany: (state) => state.selectedCompany,
    selectedFormTemplate: (state) => state.selectedFormTemplate,
    selectedTrainingRecord: (state) => state.selectedTrainingRecord,
    selectedTagName: (state) => state.selectedTagName,
    selectedTaskTemplateName: (state) => state.selectedTaskTemplateName,
    selectedTaskName: (state) => state.selectedTaskName,
    crumbRefresh: (state) => state.crumbRefresh,
    selectedProject: (store) => store.selectedProject,
    selectedContact: (store) => store.selectedContact,
    selectedSyncGuid: (store) => store.selectedSyncGuid,
    selectedFormInstance: (store) => store.selectedFormInstance,
    selectedAssignedDocument: (store) => store.selectedAssignedDocument,
    selectedDocument: (store) => store.selectedDocument,
    assignedDocumentSelected: (store) => store.assignedDocumentSelected,
    groupedTaskGroupsSelected: (store) => store.groupedTaskGroupsSelected,
    groupedDocumentsSelected: (store) => store.groupedDocumentsSelected,
    groupedUsersSelected: (store) => store.groupedUsersSelected,
    groupedCoursesSelected: (store) => store.groupedCoursesSelected,
    persistentSettings: (store) => store.persistentSettings,
    requiringMyAttention: (store) => store.requiringMyAttention,
    rmaLastCheck: (store) => store.rmaLastCheck,
    sessionTimer: (store) => store.sessionTimer,
    usersSelected: (store) => store.usersSelected,
    selectedPublicDocuments: (store) => store.selectedPublicDocuments,
    abortSignal: (state) => state.abortController.signal,
  },
  modules: {},
});
