<template>
  <v-item-group :key="reload" :value="selectedMenuItem" dark>
    <v-item
      v-for="item in menuItems"
      v-slot="{ active, on: vitem, attrs: itemAttrs }"
      :key="item.title"
    >
      <v-menu
        v-if="item.submenu.length"
        v-click-outside="() => {}"
        bottom
        offset-y
        open-on-hover
      >
        <template v-slot:activator="{ on: menu, attrs: menuAttrs }">
          <v-badge
            :style="{
              'z-index':
                item.title == 'Documents' &&
                $store.getters.requiringMyAttention > 0
                  ? '100'
                  : '0',
            }"
            :value="
              item.title == 'Documents' &&
              $store.getters.requiringMyAttention > 0
            "
            color="red"
            content="!"
            overlap
          >
            <v-btn
              v-if="calculateShow(item)"
              v-bind="{ ...menuAttrs, ...itemAttrs }"
              v-on="{ ...menu, ...vitem }"
              :class="`${active ? 'msaBlue--text' : ''} text-capitalize`"
              :outlined="active"
              :style="active ? activeBtnStyles : {}"
              :text="!active"
              @click="
                item.title == 'Administration'
                  ? null
                  : goToRoute(item.submenu[0])
              "
              height="45"
              min-width="100"
              tile
            >
              {{ item.title }}
              <v-icon x-small>mdi-chevron-down</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-list>
          <v-list-item
            v-for="subitem in item.submenu"
            v-show="
              subitem.title !== 'Mobile App' ||
              $store.getters.user.is1LifeMobileSettingEnabled
            "
            :key="subitem.title"
            ripple
            @click="goToRoute(subitem)"
            :disabled="
              subitem.title === 'Scheduler (Mobile)' &&
              !$store.getters.user.isSchedulerEnabled
            "
          >
            <v-list-item-title>{{ subitem.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-else
        :class="`
          ${active ? 'msaBlue--text' : ''} text-capitalize`"
        :outlined="active"
        :style="active ? activeBtnStyles : {}"
        :text="!active"
        @click="goToRoute(item)"
        height="45"
        min-width="100"
        tile
        v-bind="{ ...itemAttrs }"
        v-on="{ ...vitem }"
      >
        {{ item.title }}
      </v-btn>
    </v-item>

    <!-- support menu -->
    <v-item
      v-slot="{ on: vitem, attrs: itemAttrs }"
      v-if="!$store.getters.isInternalAdmin"
    >
      <v-menu open-on-hover bottom offset-y v-click-outside="() => {}">
        <template v-slot:activator="{ on: menu, attrs: menuAttrs }">
          <v-btn
            class="text-capitalize"
            text
            v-bind="{ ...menuAttrs, ...itemAttrs }"
            v-on="{ ...menu, ...vitem }"
            tile
            min-width="100"
            height="45"
          >
            Support
            <v-icon x-small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="goToLink($constants.SUPPORT_LINKS.KNOWLEDGE_BASE)"
          >
            <v-list-item-title>Knowledge Base</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="goToLink($constants.SUPPORT_LINKS.GET_LIVE_HELP)"
          >
            <v-list-item-title>Get Live Help</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="goToLink($constants.SUPPORT_LINKS.GET_LIVE_TRAINING)"
            v-if="isWebAdmin"
          >
            <v-list-item-title>Get Live Training</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToLink($constants.SUPPORT_LINKS.CONTACT_US)">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-item>
  </v-item-group>
</template>

<script>
import * as USER from '@/userMenus.js';
import * as IA from '@/internalAdminMenus.js';

export default {
  name: 'MenuNav',
  watch: {
    '$route.name': {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setupMainMenu();
        });
      },
    },
    // watch menu items to make sure
    // menu is selected properly when ia login as user
    menuItems: {
      deep: true,
      handler() {
        this.reload = !this.reload;
      },
    },
  },
  computed: {
    menuItems() {
      if (
        this.$store.getters.user.type ==
        this.$constants.USER_TYPES.INTERNAL_ADMIN
      ) {
        return IA.MENU;
      } else {
        return USER.MENU;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isWebAdmin() {
      return this.$store.getters.isWebAdmin;
    },
    isPhantomUser() {
      return (
        this.$store.getters.user.type != 2 &&
        this.$store.getters.user.isPhantomUser == 1
      );
    },
  },
  data() {
    return {
      selectedMenuItem: -1,
      activeBtnStyles: {
        background: 'linear-gradient(to bottom,  #f2f9fe 0%,#d6f0fd 100%)',
      },
      reload: false,
    };
  },
  methods: {
    calculateShow(item) {
      //hide user menus form phantom users
      if (
        this.isPhantomUser &&
        (item.title == 'Documents' || item.title == 'Training')
      ) {
        return false;
      }
      // hide administration tab for normal user
      if (
        item.title == 'Administration' &&
        this.$store.getters.user.type == this.$constants.USER_TYPES.NORMAL_USER
      ) {
        return false;
      }
      return true;
    },
    goToRoute(item) {
      if (this.$route.name != item.routeName && item.routeName != '') {
        const route = { name: item.routeName };
        if (item.query && item.query.reset) {
          this.$store.commit('updatePersistentSettings', {
            key: item.routeName,
            value: item.query.value,
          });
        }
        this.$router.push(route);
      }
      if (item.routeName == '') {
        this.launchApp(item.action);
      }
    },
    async launchApp(action) {
      var windowReference = window.open();
      let respose = await this.$axios.post('get-transfer-token?format=json', {
        loaderText: 'Connecting....',
      });
      let url = action + '&token=' + respose.data;
      windowReference.location = url;
    },
    setupMainMenu() {
      this.selectedMenuItem = this.$route.meta.parentIndex;
    },
    getWebUserManual() {
      const url =
        'https://docs.google.com/document/d/1HEQZDKoPFyrELk0lQc8je0No-G5Hce3j7cUKMaUxFmU/edit?usp=sharing';
      window.open(url, '_blank').focus();
    },
    getMobileUserManual() {
      const params = {
        loaderText: 'loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('get-mobile-user-manual?format=json', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Mobile User Manuals.zip');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
    goToLink(link) {
      window.open(link, '_blank').focus();
    },
  },
  mounted() {
    this.$nextTick(() => this.setupMainMenu());
  },
};
</script>

<style></style>
